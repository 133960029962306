import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'
import {Languages} from '../components/context/data/Languages'

const METRO_PROMOTIONS = "/metropromotions"

class ValidationApi extends BaseApi {
  async sendVerificationCode(phoneNumber, cultureId) {
    try {
      const response = await this.post(`${METRO_PROMOTIONS}/sendCode`, {PhoneNumber: phoneNumber, OfferId: process.env.REACT_APP_CONTROL_OFFER_ID, CultureId: cultureId})
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 1 ? Languages.en.errors.VERIFICATION_PHONE : Languages.sp.errors.VERIFICATION_PHONE
      throw new Error(newError.replace("{phoneNumber}", phoneNumber))
    }
  }

  async validateVerificationCode(phoneNumber, verificationCode, cultureId) {
    try {
      const response = await this.post(`${METRO_PROMOTIONS}/validateCode`, {PhoneNumber: phoneNumber, VerificationCode: verificationCode})
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 1 ? Languages.en.errors.VERIFICATION_VALIDATION : Languages.sp.errors.VERIFICATION_VALIDATION
      throw new Error(newError.replace("{verificationCode}", verificationCode).replace("{phoneNumber}", phoneNumber))
    }
  }

  async validateAddress(iSubmission, user, offerId) {
    let region = user.region
    if (!region) {
      region = user.state
    }

    const submissionRequest = {
      offerId: offerId,
      Address1: user.address1,
      Address2: user.address2,
      City: user.city,
      State: region,
      PostalCode: user.postalCode
    }
    const isubmitId = iSubmission.iSubmitId
    try {
      const response = await this.post(`${METRO_PROMOTIONS}/validateAddress/${isubmitId}`, submissionRequest)
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      throw new Error("Unable to validate address. Try again later.")
    }
  }
}

export default new ValidationApi()
