import React from 'react'
import PropTypes from "prop-types"

import styled from 'styled-components'

const LabelText = styled.div`
  font-size: 24px;
  font-family: "TTNorms-Bold";
  text-transform: capitalize;
`
export const ChooserItemComponent = ({src, label, labelColor}) => {
  return (
    <div style={{
      flex: '1 0',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 0'
    }}>
      <div style={{flex: '1 1'}}></div>
      <img src={src}
        alt="Chosen Item"
        style={{
          maxHeight: '400px',
          maxWidth: '400px',
          margin: '0px auto',
          width: '100%'
        }}/>
      <LabelText style={{color: labelColor}}>{label}</LabelText>
    </div>
  )
}

ChooserItemComponent.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  labelColor: PropTypes.string
}
