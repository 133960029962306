import React, {Component} from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import AnalyticsService from "../../utils/AnalyticsService"
import {GTM_EVENTS} from "../../utils/GtmEvents"

import "./Tabs.css"

export {default as Tab} from "./Tab"

export default class Tabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      activeIndex: 0
    }
  }

  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.node
    ]).isRequired
  }

  renderAnchor(child, index) {
    if (child.props.href && child.props.href.length > 0) {
      return (
        <a onClick={this.onTabClick.bind(this, index)} href={child.props.href} rel="noopener noreferrer" target="_blank">
          {child.props.label}
        </a>)
    } else {
      return (
        <div onClick={this.onTabClick.bind(this, index)}>
          {child.props.label}
        </div>)
    }
  }

  onTabClick(tabIndex) {
    this.setState({
      activeIndex: tabIndex
    })

    let action = ""
    switch (tabIndex) {
    case 0:
      action = "Card Info"
      break
    case 1:
      action = "Transaction History"
      break
    case 2:
      action = "Cardholder Agreement"
      break
    default:
      action = "Card Info"
      break
    }

    AnalyticsService.sendEvent(
      "Reward Info",
      action,
      window.location.pathname,
      {
        event: "card info tabs"
      }
    )
    AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: "Reward_Info", category: window.location.pathname, action: "click_tab", label: "ard_info_tabs"})
  }
  

  renderTabs() {
    return React.Children.map(this.props.children, (child, index) => {
      return (
        <li className={classNames({"isActive": index === this.state.activeIndex})}>
          {this.renderAnchor(child, index)}
        </li>
      )
    })
  }

  renderContent() {
    const {children} = this.props
    const {activeIndex} = this.state
    return React.Children.map(children, (child, index) => {
      return (
        <div className={classNames({
          "isActive": index === activeIndex
        })}>
          {children[index].props.children}
        </div>
      )
    })
  }

  render() {
    return (
      <div className="tabs mb-5">
        <ul className="tabs-menu">
          {this.renderTabs()}
        </ul>
        <div className="tabs-content">
          {this.renderContent()}
        </div>
      </div>
    )
  }
}
