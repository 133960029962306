import React, {useState, createContext, useEffect} from "react"
import PropTypes from "prop-types"
import {Languages} from "./data/Languages"

const initialState = {
  english: true,
  language: Languages.en,
  toggleLanguage: () => {}
}

export const LanguageContext = createContext(initialState)

export const LanguageProvider = (props) => {
  const [english, setEnglish] = useState(true)

  useEffect(() => {
    const isEnglish = sessionStorage.getItem("english")
    //on first render, session value won't exist
    //default language is english
    if (isEnglish === null || isEnglish === "true") {
      setEnglish(true)
    } else {
      setEnglish(false)
    }
  }, [english])

  const toggleLanguage = () => {
    const isEnglish = !english
    sessionStorage.setItem("english", JSON.stringify(isEnglish))
    setEnglish(isEnglish)
  }

  const language = english ? Languages.en : Languages.sp

  return (
    <LanguageContext.Provider value={{language, toggleLanguage}}>
      {props.children}
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.element
}
