import React, {useContext} from "react"
import PropTypes from "prop-types"
import {FileInput} from "./index"
import {OrangeButton, ErrorMessage} from "../styled"
import { LanguageContext, AppContext } from "../context"
import "../../css/Faq.css";

export const UploadForm = ({instructions, onSubmit, isSubmitting, errorMessage}) => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  const renderStepOne = () => {
    if(state.offer.IsHint) {
      return <span style={{color: "white", fontFamily: "TTNorms-Bold", fontSize: "16px", textAlign: "left"}}>
          <br />What you'll need:<br />
          <br />Image of final bill from your old ISP should include...
          <ul>
            <li>Name of internet service provider.</li>
            <li>Previous provider account number.</li>
            <li>Name on account and address of service. Either the name on the bill or the adress must match your Metro billing information.</li>
            <li>Date of final bill.</li>
            <li>Account cancelation date.</li>
            <li>Amount of early termination fee (ETF) owed.</li>
            <li>Total balance owed.</li>
          </ul>
        </span>
    }
  }

  const renderStepTwo = () => {
    if(state.offer.IsHint) {
      return <span style={{color: "white", fontFamily: "TTNorms-Bold", fontSize: "16px", textAlign: "left"}}>
          <br />You can upload a .pdf, .jpg, or .png image with a maximum size of 5 MB. PDF image can be up to 20 pages.<br />
          <br />If bill is more than one page, please upload pages that contain the information listed above, not just the summary page.
        </span>
    }
  }

  const renderStepThree = () => {
    if(state.offer.IsHint) {
      return <span style={{color: "white", fontFamily: "TTNorms-Bold", fontSize: "16px", textAlign: "left"}}>
          <br />You will receive your reimbursement in a single payment in the form of a Prepaid Virtual Card.<br />
          <br />Note: Switching Cost Reimbursements will expire 6 months from issuance.
        </span>
    }
  }

  if(instructions === undefined || instructions === null) {
    instructions = ''
  }
  
  return (
    <>
      <div><span>&nbsp;</span></div>
      <div className="row justify-content-left titleOrange24Text">        
        <div className="">
          <span className="">1 &nbsp;</span>
            {language.upload_step.FIRST_SECTION_TITLE}
        </div>
        {renderStepOne()}
      </div>

      <div><span>&nbsp;</span></div>
      <div className="row justify-content-left titleOrange24Text">
        <div className="">
          <span className="">2 &nbsp;</span>
            {language.upload_step.SECOND_SECTION_TITLE}
        </div>
        {renderStepTwo()}
      </div>

      <div className="upload-file">
        <p>{instructions}</p>
        <FileInput key={"0"}/>
      </div>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <div><span>&nbsp;</span></div>
      <div className="row justify-content-left titleOrange24Text">        
        <div className="">
          <span className="">3 &nbsp;</span>
            {language.upload_step.THIRD_SECTION_TITLE}            
        </div>
        {renderStepThree()}
      </div>

      <div className="row justify-content-left titleOrange24Text">        
        <div className=""><span className="">&nbsp;</span></div>        
      </div>

      <OrangeButton
        className="btn"
        style={{marginBottom: "20px"}}
        primary
        id="btnSubmit"
        onClick={onSubmit}
        disabled={isSubmitting}>
        {isSubmitting ? language.form.button.UPLOADING : language.form.button.SUBMIT}
      </OrangeButton>
    </>
  )
}

UploadForm.propTypes = {  
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
}
