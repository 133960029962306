import React from "react"
import PropTypes from "prop-types"

import "../../css/Layout.css"

export const ThankYouFulfillmentComponent = ({thankYouLineOneText, thankYouLineTwoText}) => {
    return (
        <section>
            <div className="row justify-content-center">
                <div className="col-7" style={{textAlign: "center", marginTop: "20px"}}>
                    <div className="instructionalWhite14Text">{thankYouLineOneText}</div>
                    <div className="instructionalWhite14Text">{thankYouLineTwoText}</div>
                </div>
            </div>
        </section>
    )
}

ThankYouFulfillmentComponent.propTypes = {
    customerStatus: PropTypes.string,
    thankYouLineOneText: PropTypes.string,
    thankYouLineTwoText: PropTypes.string,
}