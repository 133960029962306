import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'

class ReCaptchaApi extends BaseApi {
  async verifyReCaptchaV3Callback(reCaptchaToken) {
    try {
      const response = await this.post(`/metropromotions/verifyReCaptchaV3?controlOfferId=${process.env.REACT_APP_CONTROL_OFFER_ID}`, {ReCaptchaToken: reCaptchaToken})
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      throw new Error('ReCaptcha unable to verify')
    }
  }
}

export default new ReCaptchaApi()
