import React, { useContext, useEffect } from "react"
import MediaQuery from 'react-responsive'
import PropTypes from "prop-types"

import { AppContext } from "../components/context/AppContext"

import { ROUTE_PATHS } from "../utils/routes"
import { GTM_EVENTS } from "../utils/GtmEvents"
import { SubmissionApi } from "../api"
import { FoundPromotionComponent, TermsAndConditionsComponent, RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm } from "../components"
import AnalyticsService from "../utils/AnalyticsService"

export const Promotion = ({ history }) => {
  const { state, dispatch } = useContext(AppContext)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.PROMOTION);
  })

  if (state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  let offerHeaderImageUrl = ""
  let offerHeaderImageType = ""
  let offerHeaderImageBytes = ""
  let offerLineOneText = "go back to school"
  let offerLineTwoText = "with metro"
  let termsText = ""
  if (state.offer) {
    offerHeaderImageUrl = state.offer.HeaderImageUrl
    offerHeaderImageType = state.offer.HeaderImageType
    offerHeaderImageBytes = state.offer.HeaderImageBytes
    offerLineOneText = state.offer.HeaderLineOne
    offerLineTwoText = state.offer.HeaderLineTwo
    termsText = state.offer.TermsAndConditions
  }

  
  
  const submitForm = async (values, {setSubmitting, setStatus}) => {
    if (state.offer.RequireRegistration) {
      let address = await SubmissionApi.getHintAddress(state.iSubmission.phone, state.offer.OfferId);
      dispatch({type: "SETHINTADDRESS", payload: address})
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.ACCEPT_TERMS, category: "Promotion", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Promotion", { event: GTM_EVENTS.ACCEPT_TERMS })
      history.push(ROUTE_PATHS.REGISTER)
    } else if (state.offer.RequireChooser) {
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.ACCEPT_TERMS, category: "Promotion", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Promotion", { event: GTM_EVENTS.ACCEPT_TERMS })
      history.push(ROUTE_PATHS.CHOOSE)
    } else if (state.offer.RequireFileUpload) {
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.ACCEPT_TERMS, category: "Promotion", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Promotion", { event: GTM_EVENTS.ACCEPT_TERMS })
      history.push(ROUTE_PATHS.UPLOAD)
    } else {
      try {
        // Pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route
        const iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, 0)
        const updatedISubmission = await SubmissionApi.finalizeSubmission(iSubmission, state.user, state.cultureId)
        await SubmissionApi.sendThankYouSms(iSubmission, state)
        dispatch({ type: "SETISUBMISSION", payload: updatedISubmission })
        dispatch({ type: "SETSUBMITTED", payload: true })
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.ACCEPT_TERMS, category: "Promotion", action: "Upload_Site",  label: "Submit"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "Promotion", { event: GTM_EVENTS.ACCEPT_TERMS })
        history.push(ROUTE_PATHS.STATUS)
      } catch (error) {
        setStatus(error.message)
        setSubmitting(false)
      }
    }
  }

  return (
    <section>
      <div className="container hero3">
        <MediaQuery query="(min-width: 1450px)">
          <LeftMagnetSwarm />
          <RightMagnetSwarm />
        </MediaQuery>
        <MediaQuery query="(max-width: 1449px)">
          <MediaQuery query="(min-width: 992px)">
            <LeftNarrowMagnetSwarm />
            <RightNarrowMagnetSwarm />
          </MediaQuery>
        </MediaQuery>
        <div className="row">
          <div className="col">
            <FoundPromotionComponent offerLineOne={offerLineOneText} offerLineTwo={offerLineTwoText} offerHeaderImageUrl={offerHeaderImageUrl} offerHeaderImageType={offerHeaderImageType} offerHeaderImageBytes={offerHeaderImageBytes} />
          </div>
        </div>
        <div className="row justify-content-center">
          <MediaQuery query="(min-width: 992px)">
            <div className="col-6">
              <TermsAndConditionsComponent onSubmit={submitForm} terms={termsText} />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 991px)">
            <div className="col">
              <TermsAndConditionsComponent onSubmit={submitForm} terms={termsText} />
            </div>
          </MediaQuery>
        </div>
      </div>
    </section>
  )
}

Promotion.requiresAuth = true

Promotion.propTypes = {
  history: PropTypes.object.isRequired
}
