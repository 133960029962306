import React from "react"
import classnames from "classnames"
import PropTypes from "prop-types"
import "../css/LoadingSpinner.css"

export const LoadingComponent = ({isChatSpinner}) => {
  const spinnerClass = classnames({
    'sk-fading-circle': true,
    'chat-spinner': isChatSpinner
  })

  return (
    <div className={spinnerClass}>
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  )
}

LoadingComponent.propTypes = {
  isChatSpinner: PropTypes.bool
}
