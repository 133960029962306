import React from "react"
import PropTypes from "prop-types"
import ReCaptcha from "react-google-recaptcha"

import {ErrorMessage} from "../styled"

export const ReCAPTCHAInput = ({
  field,
  form: {touched, errors, setFieldValue},
  ...props
}) => {
  return (
    <div>
      <ReCaptcha
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2 || ""}
        {...field}
        {...props}
        onChange={(val) => { setFieldValue("recaptcha", val) }}
      />
      {touched[field.name] &&
        errors[field.name] && <ErrorMessage style={{maxWidth: '300px', margin: '0px auto'}}>{errors[field.name]}</ErrorMessage>}
    </div>
  )
}

ReCAPTCHAInput.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func
}
