export const GTM_EVENTS = {
  OFFER_EXPIRED: 'OFFER_EXPIRED',
  SEND_VERIFICATION_CODE: 'SEND_VERIFICATION_CODE',
  SUBMISSION_FOUND: 'SUBMISSION_FOUND',
  SUBMISSION_CREATED: 'SUBMISSION_CREATED',
  LOGIN: 'LOGIN',
  ACCEPT_TERMS: 'ACCEPT_TERMS',
  REGISTRATION_SUBMITTED: 'REGISTRATION_SUBMITTED',
  META_CARD_ACTIVATED: 'META_CARD_ACTIVATED',
  META_CARD_VIEWED: 'META_CARD_VIEWED',
  CITI_CARD_ACTIVATED: 'CITI_CARD_ACTIVATED',
  CITI_CARD_VIEWED: 'CITI_CARD_VIEWED',
  FILE_UPLOAD: 'FILE_UPLOAD',
  LANGUAGE_TOGGLED: 'LANGUAGE_TOGGLED',
  EVENT: 'METRO',
  CHOOSE_SUBMITTIED: 'CHOOSE_SUBMITTED'
}
