import styled from "styled-components"


export const ThankYouSection = styled.section`
  background-image: url("/images/287884-bg-full-youngamerica-02.mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;

  @media (min-width: 768px)  {
    background-image: url("/images/287884-bg-full-youngamerica-02.desktop.jpg");
}

`
