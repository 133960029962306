import React, {useState, useContext} from "react"
import { AppContext, LanguageContext } from "../context"
import {useDropzone} from "react-dropzone"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFilePdf} from "@fortawesome/free-solid-svg-icons"
import {Dropzone, ThumbnailContainer, UploadButton, XButton, ErrorMessage} from "../styled"
import { UploadCriteria } from "../StyledComponents"

//for some reason the thumbnail div would not work as styled component :(
const thumbnailStyles = {
  marginBottom: 8,
  marginRight: 8,
  position: "relative"
}

const previewStyles = {
  display: "inline",
  width: 100,
  height: 100
}

const uploadNameStyles = {
  width: 100,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}

export const FileInput = () => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [errorMessage, setErrorMessage] = useState("")

  const {
    getRootProps,
    getInputProps,
    isDragAccept,
    isDragActive,
    isDragReject,
    open,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: "image/jpeg, image/jpg, image/png, application/pdf",
    maxSize: 5242880,
    onDrop: (acceptedFiles, rejectedFiles)  => {
      setErrorMessage("")
      let errorMessage = []
      if (rejectedFiles.length > 0) {
        errorMessage.push(language.errors.upload.MAX_FILE_SIZE)
      }

      const maxAcceptedFiles = 16 - state.files.length - acceptedFiles.length
      if (state.files.length + acceptedFiles.length > 15) {
        errorMessage.push(language.errors.upload.MAX_UPLOADS)
      }

      let addedFiles = []
      for (let i = 0; i < acceptedFiles.length; i++) {
        const existing = state.files.filter((file) => file.name === acceptedFiles[i].name)
        if (existing.length === 0 && (state.files.length + addedFiles.length < 15)) {
          addedFiles.push(acceptedFiles[i])
        }
      }
      if (addedFiles.length < acceptedFiles.length && addedFiles.length < maxAcceptedFiles) {
        errorMessage.push(language.errors.upload.DUPLICATES)
      }
      if (errorMessage.length > 0) {
        setErrorMessage(errorMessage.join(" "))
      }

      const fileList = [...state.files, ...addedFiles]
      // this needs to be dispatched to context
      dispatch({type: "SETFILES", payload: fileList.map(file => Object.assign(file, {preview: URL.createObjectURL(file)}))})
    }
  })

  const removeFile = (fileToRemove) => {
    const filtered = state.files.filter((file) => file.name !== fileToRemove.name)
    dispatch({type: "SETFILES", payload: filtered})
  }

  const renderThumbnails = state.files.map(file => (
    <div style={thumbnailStyles} key={file.name}>
      {
        file.type === "application/pdf"
          ? <FontAwesomeIcon icon={faFilePdf} size="5x" style={previewStyles}/>
          : <img src={file.preview} style={previewStyles} alt={file.name} />
      }
      <div style={uploadNameStyles}>{file.name}</div>
      <XButton className="text-center" onClick={(e) => removeFile(file,e)}>&times;</XButton>
    </div>
  ))

  return (
    <>
      <Dropzone {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
        <input {...getInputProps({disabled: false})}/>
        <p>{language.upload.DRAG_AND_DROP}</p>
        <p>or</p>
        <UploadButton primary style={{cursor: "pointer"}} onClick={open}>{language.form.button.CHOOSE_FILE}</UploadButton>
      </Dropzone>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <UploadCriteria>
        <div>{language.upload.ACCEPTABLE_FORMATS}<span style={{fontWeight: "bold"}}>{language.upload.FILE_FORMATS}</span></div>
        <div>{language.upload.MAX_SIZE}<span style={{fontWeight: "bold"}}>5MB</span></div>
      </UploadCriteria>
      <hr/>
      <p style={{textAlign: "left", textDecoration: "underline", color: "#46196e", fontWeight: "bold"}}>{language.upload.MY_UPLOADS}</p>
      <ThumbnailContainer>
        {renderThumbnails}
      </ThumbnailContainer>
    </>
  )
}
