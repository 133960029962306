/* eslint-disable react/prop-types */
import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"
import {ErrorMessage} from "../styled"

export const CheckboxInput = ({
  field, // { name, value, onChange, onBlur }
  form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {

  const classes = classnames({
    'form-check-input': true
  })
  const id = `checkbox-${field.name}`


  return (
    <div className="form-group form-check" style={props.style}>
      <input type="checkbox"
        {...field}
        {...props}
        id={id}
        className={`${classes} ${props.className}`}
        checked={field.value}
        onChange={() => {
          form.setFieldValue(field.name, !field.value)
        }} />
      <label className="form-check-label" htmlFor={id}>{props.label}</label>
      {form.errors[field.name] && <ErrorMessage>{form.errors[field.name]}</ErrorMessage>}
    </div>
  )
}

CheckboxInput.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.object
}
