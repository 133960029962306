import BaseApi from "./BaseApi"
import * as Sentry from "@sentry/browser"
import {Languages} from "../components/context/data/Languages"

const ACTIVATE = "/activate"
const CARD_INFORMATION = "/view"
const TRANSACTIONS = "/transactions"

class VirtualCardApi extends BaseApi {
  async getVirtualCardInfo(submitId, debitCardDisbursedId, cultureId) {
    try {
      const response = await this.get(CARD_INFORMATION, {
        submitId: submitId,
        debitCardDisbursedId: debitCardDisbursedId,
        host: window.location.hostname
      })
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 2 ? Languages.sp.errors.CARD_INFO : Languages.en.errors.CARD_INFO
      throw new Error(newError)
    }
    // return  ({
    //   "Balance": 1,
    //   "Status": 2,
    //   "ExternalKey": "DC32846514",
    //   "ClientID": 938,
    //   "Error": ""
    // })
  }

  async getVirtualCardTransactions(submitId, debitCardDisbursedId, cultureId) {
    try {
      const response = await this.get(TRANSACTIONS, {
        submitId: submitId,
        debitCardDisbursedId: debitCardDisbursedId
      })
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 2 ? Languages.sp.errors.TRANSACTIONS : Languages.en.errors.TRANSACTIONS
      throw new Error(newError)
    }
    // return ([
    //   {
    //     "Date": "2018-07-23T20:48:59",
    //     "Description": "VALUE LOAD Value Load",
    //     "Type": "Value load",
    //     "Amount": 1
    //   }
    // ])
  }

  async activateVirtualCard(submitId, cultureId) {
    try {
      const response = await this.post(`${ACTIVATE}/?submitId=${submitId}`)
      if (response.data === 'Household limit reached') {
        return 0
      }
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 2 ? Languages.sp.errors.ACTIVATION : Languages.en.errors.ACTIVATION
      throw new Error(newError)
    }
  }
}

export default new VirtualCardApi()
