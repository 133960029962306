import React, {useContext} from "react"
import {LanguageContext} from "./context"
import '../css/Text.css'

export const MaintenanceComponent = ({text}) => {
  const {language} = useContext(LanguageContext)
  return (
    <section>
      <div className="row">
        <div className="col-lg-6 col-md-12">
          <div className="layout-purple align-middle" style={{paddingTop: "1rem", paddingBottom: "1rem", minWidth: "18rem"}}>
            <p className="titleOrange42Text">{language.maintenance.HEADER_TEXT}</p>
            <p className="titleWhite24Text">{text ? text : language.maintenance.MESSAGE}</p>
            <h2 className="titleOrange24Text">{language.maintenance.THANKS}</h2>
          </div>
        </div>
      </div>
    </section>
  )
}
