import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"

import Tabs, {Tab} from "../components/tabs/Tabs"

import {AppContext,LanguageContext} from "../components/context"
import {ROUTE_PATHS} from "../utils/routes"
import {VirtualCardApi} from "../api"

import {MetaCardInformationForm, MetaCardTransactionsForm} from "../components/forms"
import pdf from "../CardholderAgreement.pdf"
import es_pdf from "../ES_CardholderAgreement.pdf"
import AnalyticsService from "../utils/AnalyticsService"

export const MetaVirtualCard = ({history}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [metaPdf, setMetaPdf] = useState()

  if (!state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  useEffect(() => {
    async function refreshCardInfo() {
      const iSubmission = state.iSubmission
      const debitCardDisbursedIdField = iSubmission.offerFields.find((field) => field.key === 'DebitCardDisbursedID')
      if (debitCardDisbursedIdField) {
        const debitCardDisbursedId = Number(debitCardDisbursedIdField.value)
        const metaCardInfo = await VirtualCardApi.getVirtualCardInfo(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
        const metaCardTransactions = await VirtualCardApi.getVirtualCardTransactions(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
        dispatch({type: "SETMETAVIRTUALCARD", payload: {info: metaCardInfo, transactions: metaCardTransactions}})
      }
      setMetaPdf(state.cultureId === 1 ? pdf : es_pdf)
    }
    refreshCardInfo()
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.META_VIRTUAL_CARD);
  }, [state.iSubmission, dispatch, state.cultureId])

  return (
    <section>
      <div className="container hero3" style={{paddingTop: "10px"}}>
        <h2 className="mb-3 titleWhite24Text">
          {language.virtualCard.SHOP}
        </h2>
        <h5 className="instructionalWhite14Text">{language.virtualCard.CARD_USAGE}</h5>
        <Tabs>
          <Tab label={language.virtualCard.CARD_INFO}>
            <MetaCardInformationForm />
          </Tab>
          <Tab label={language.virtualCard.HISTORY}>
            <MetaCardTransactionsForm />
          </Tab>
          <Tab label={language.virtualCard.CARD_AGREEMENT}>
            <iframe title="Card Agreement" height="550" width="100%" src={metaPdf} allowFullScreen id="metaCardAgreement" />
          </Tab>
        </Tabs>
      </div>
    </section>
  )
}

MetaVirtualCard.requiresAuth = true

MetaVirtualCard.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
