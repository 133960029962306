import {ReCaptchaApi} from "../api"

export const verifyReCaptchaV3 = (token) => {
  try {
    return ReCaptchaApi.verifyReCaptchaV3Callback(token)
  } catch (error) {
    return error
  }
}

export const getDateAsString = (date) => new Date(date).toLocaleDateString()

export const parseClientFaq = (faq) => {
  const faqHeaders = [...new Set(faq.map(question => question.HeaderText))]
  const parsedFaq = []

  faqHeaders.forEach((header) => {
    const questionArray = faq
      .filter((question => question.HeaderText === header))
      .sort((a, b) => a.ItemDisplayOrder - b.ItemDisplayOrder)

    const question = {header: header, questions: questionArray }

    parsedFaq.push(question)
  })

  return parsedFaq
    .sort((q1, q2) => q1.questions[0].headerDisplayOrder - q2.questions[0].headerDisplayOrder)

}

export const createMarkup = (text) => {
  return {__html: text}
}

export const validateImei = (imei) => {
  const imeiArray = imei.split("")

  const validationArray = imeiArray.map((digit, index) => {
    let parsedDigit = parseInt(digit, 10)

    if (index % 2 !== 0) {
      parsedDigit = parsedDigit * 2
    }

    if (parsedDigit > 9) {
      parsedDigit = ((parsedDigit % 10) + (parsedDigit / parsedDigit))
    }

    return parsedDigit
  })

  const validationTotal = validationArray.reduce( (accumulator, currentValue) => accumulator + currentValue)

  return validationTotal % 10 === 0
}

export const isEmpty = (obj) => {
  if (obj == null) { return true }
  if (obj.length > 0) { return false }
  if (obj.length === 0) { return true }
  if (typeof obj !== "object") { return true }

  let hasOwnProperty = Object.prototype.hasOwnProperty

  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) { return false }
  }

  return true
}

export const isEdgeOrIE = () => {
  const isIE = false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia

  return isIE || isEdge
}
