import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'
import {Languages} from "../components/context/data/Languages"

export const AUTH_ENDPOINT = '/auth-token'
export const VALIDITY_ENDPOINT = '/offer/valid'
export const CHECK_RECAPTCHA_ENDPOINT = '/check-recaptcha'

export class AuthApi extends BaseApi {
  isAuthenticated = () => {
    const userInfo = this.getUserInfo()

    if (userInfo) {
      const current = Math.floor(Date.now() / 1000)
      const exp = userInfo.exp
      if (exp < current) {
        this.deleteAuthToken()
      }
    }

    return this.getAuthToken()
  }

  async login(phoneNumber, code, offerId) {
    try {      
      const res = await this.post(AUTH_ENDPOINT, {
        code,
        phone: phoneNumber.trim(),
        strategy: process.env.REACT_APP_STRATEGY,
        offerId: offerId
      })

      const token = res.data
      this.setAuthToken(token)

      return {
        token
      }
    } catch (error) {
      Sentry.captureException(error)
      this.deleteAuthToken()
      return Promise.reject(new Error(`Unable to determine eligibility for phone number ${phoneNumber} and claim code ${code}. Try again later.`))
    }
  }

  async checkReCAPTCHA(response, cultureId) {
    try {
      const res = await this.post(CHECK_RECAPTCHA_ENDPOINT, {
        strategy: process.env.REACT_APP_STRATEGY,
        response
      })

      return res
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 2 ? Languages.sp.errors.GENERAL : Languages.en.errors.GENERAL
      throw new Error(newError)
    }
  }
}

export default new AuthApi()
