import React, {useContext, useEffect} from "react"
import {LanguageContext} from "../components/context"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

export const Eligibility = () => {
  const {language} = useContext(LanguageContext)
  
  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.ELIGIBILITY);
  })
  
  return (
        <>
        <div className="row">
          <div className="col">
            <div className="layout-purple text-center" style={{paddingTop: "1rem", paddingBottom: "1rem", minWidth: "18rem"}}>
              <p className="instructionalWhite14Text">{language.eligibility.MESSAGE_ONE}</p>
              <p className="instructionalWhite14Text">{language.eligibility.MESSAGE_TWO}</p>
            </div>
          </div>
        </div>
        </>
  )
}
