import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'

class CcpaApi extends BaseApi {
  async getBlackList() {
    try {
      const response = await this.get('/getBlackList')
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      return ''
    }
  }
}

export default new CcpaApi()
