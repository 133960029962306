import React from 'react'
import PropTypes from 'prop-types'

import {createMarkup} from "../utils/Helpers"

export const InstructionsComponent = ({instructionsText}) =>
  <div className="mt-3">
    <p dangerouslySetInnerHTML={createMarkup(instructionsText)}></p>
  </div>

InstructionsComponent.propTypes = {
  item: PropTypes.string,
  instructionsText: PropTypes.string
}
