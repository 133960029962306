import React, {useContext} from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field} from "formik"
import {ReCaptcha} from 'react-recaptcha-v3'

import {AppContext, LanguageContext} from "../context"
import {ReCAPTCHAInput, TextInput} from "../inputs"
import {OrangeButton, ErrorMessage} from "../styled"

export const LoginForm = ({onSubmit, claimCode}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  const phoneRegEx = /^\s*[(]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})\s*$/
  const nameRegEx = /^([a-zA-Z\s]*$)/

  const saveReCaptchaV3Token = (token) => {
    dispatch({type: "SETRECAPTCHAV3LOGINTOKEN", payload: token})
  }

  const initialValues = {
    phoneNumber: "",
    firstName: "",
    lastName: "",
    recaptcha: ""
  }

  const ConstantSchema = {
    phoneNumber: Yup
      .string()
      .required(`${language.form.required.PHONE}`)
      .matches(phoneRegEx, `${language.form.validation.PHONE}`),
    firstName: Yup
      .string()
      .required(`${language.form.required.FIRST_NAME}`)
      .matches(nameRegEx, `${language.form.validation.NAME}`),
    lastName: Yup
      .string()
      .required(`${language.form.required.LAST_NAME}`)
      .matches(nameRegEx, `${language.form.validation.NAME}`),
  }

  const ReCaptchaSchema = {
    recaptcha: Yup
      .string()
      .nullable()
      .required(`${language.form.required.RECAPTCHA}`)
  }

  const LoginWithReCaptchaSchema = Yup.object().shape({...ConstantSchema, ...ReCaptchaSchema})
  const LoginSchema = Yup.object().shape(ConstantSchema)

  return (
    <>
      <Formik initialValues={initialValues} validateOnBlur={false} validationSchema={state.isReCaptchaV3Valid ? LoginSchema : LoginWithReCaptchaSchema} onSubmit={onSubmit}>
        {({status, isSubmitting}) => (
          <Form>
            <p className="requiredFieldsText">{language.form.REQUIRED_FIELDS_INDICATOR}</p>
            <div className="row">
              <div className="form-group col-md-12">
                <Field name="phoneNumber" component={TextInput} autoFocus placeholder={language.form.placeholder.PHONE} type="tel" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <Field name="firstName" component={TextInput} placeholder={language.form.placeholder.FIRST_NAME} type="text" />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-12">
                <Field name="lastName" component={TextInput} placeholder={language.form.placeholder.LAST_NAME} type="text" />
              </div>
            </div>
            {!state.isReCaptchaV3Valid && <Field name="recaptcha" component={ReCAPTCHAInput} />}
            {state.isReCaptchaV3Valid && <ReCaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3} action='Login' verifyCallback={saveReCaptchaV3Token} />}
            <div className="mt-3 text-center">
              <OrangeButton type="submit" className="btn" id="btnSubmit" disabled={isSubmitting} primary>{claimCode ? language.form.button.NEXT : language.form.button.SEND_CODE}</OrangeButton>
              {status && <ErrorMessage>{status}</ErrorMessage>}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

LoginForm.propTypes = {
  claimCode: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
}
