import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'
import {Languages} from "../components/context/data/Languages"
const SUBMISSION_ENDPOINT = "/submissions"

class SubmissionApi extends BaseApi {
  async getISubmission(offerId, submitId) {
    // submitId should only be > 0 when this is being used for the special exceptions offer
    // When submitId = 0, this works as it always has - it gets the ISubmission and Submission info for the customer
    // When submitId > 0, this returns a dummy ISubmission for the special exceptions offer and the real Submission
    try {
      const response = await this.get(`/submissions?offerId=${offerId}&submitId=${submitId}`)
      const iSubmissions = response.data
      let iSubmission = {}
      if (iSubmissions.length > 0) {
        iSubmission = iSubmissions[0]
      }
      return iSubmission
    } catch (error) {
      Sentry.captureException(error)
      throw new Error('Unable to get ISubmission')
    }
  }

  async finalizeSubmission(iSubmission, user, cultureId) {
    try {
      const isubmissionData = {
        firstName: user.firstName,
        lastName: user.lastName,
        Address1: user.address1,
        Address2: user.address2,
        City: user.city,
        State: user.state,
        PostalCode: user.postalCode,
        email: user.email,
        phone: user.phoneNumber,
        offerFields: iSubmission.offerFields,
        cultureId: cultureId,
        isResend:iSubmission.isResend?iSubmission.isResend:false
        // fileGuids:
      }
      const isubmitId = iSubmission.iSubmitId
      const response = await this.post(`${SUBMISSION_ENDPOINT}/${isubmitId}`, isubmissionData)
      if (response.data === 'Store address.') {
        throw new Error(response.data)
      }
      return response.data
    } catch (error) {
      let newError
      if (error.message === 'Store address.') {
        newError = cultureId === 2 ? Languages.sp.errors.STORE_ADDRESS : Languages.en.errors.STORE_ADDRESS
      } else {
        newError = cultureId === 2 ? Languages.sp.errors.PROCESS_REQUEST : Languages.en.errors.PROCESS_REQUEST
      }
      Sentry.captureException(error)
      throw new Error(newError)
    }
  }

  async sendThankYouSms(iSubmission, state) {
    try {      
      const smsData = {
        PhoneNumber: state.user.phoneNumber,
        OfferId: state.offer.OfferId?state.offer.OfferId:iSubmission.offerFields,
        ClaimCode: state.offer.ClaimCode?state.offer.ClaimCode:iSubmission.claimCode,
        SubmitId: iSubmission.SubmitId,
        ISubmitId: iSubmission.iSubmitId,
        CultureId: state.cultureId
      }
      await this.post(`/metropromotions/sendThankYou`, smsData)
    } catch (error) {
      Sentry.captureException(error)
      throw new Error(error.response.data)
    }
  }

  async upload(files, iSubmitId) {
    try {
      const data = new FormData()

      files.forEach((file) => data.append(file.path, file))

      const endpoint = `${SUBMISSION_ENDPOINT}/${iSubmitId}/upload`
      const response = await this.post(endpoint, data)
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      throw new Error(error)
    }
  }

  async getHintAddress(telephone,offerId){
    try {
      const response = await this.get(`/submissions/hintAddress?offerId=${offerId}&telephone=${telephone}`)
      const submission = response.data
      return submission
    } catch (error) {
      Sentry.captureException(error)
      throw new Error('Unable to get Submission address')
    }
  }

  async DeleteSubmissionImages(isubmitID, offerId)
  {
    try
    {
      const response = await this.get(`images/DeleteImages?submitId=${isubmitID}&offerid=${offerId}`)
      return response;
    }
    catch(error)
    {
      throw new Error('Unable to delete Submission images')
    }
  }

  async UpdateDeniedSubmission(isubmitID, offerId)
  {
    try
    {
      const response = await this.get(`submissions/Resubmit?offerId=${offerId}&submissionId=${isubmitID}`)
      return response;
    }
    catch(error)
    {
      throw new Error('Unable to Update Denied Submission Status')
    }
  }
}


export default new SubmissionApi()
