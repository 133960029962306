import React from "react"
import PropTypes from "prop-types"

import "../../css/RadioToggle.css"

export const RadioButtonInput = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {

  return (
    <div className="radio-toggle-fieldset">
      <input
        name={name}
        id={id}
        type="radio"
        value={id} // could be something else for output?
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

RadioButtonInput.propTypes = {
  field: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
}
