import React, {useContext, useEffect} from "react"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"

import {AppContext, LanguageContext} from "../components/context"

import {
  FoundPromotionComponent,
  RewardCodeFulfillmentComponent,
  InCommFulfillmentComponent,
  VirtualCardFulfillmentComponent,
  RightMagnetSwarm, LeftMagnetSwarm,
  RightNarrowMagnetSwarm,
  LeftNarrowMagnetSwarm} from "../components"
import {ThankYouFulfillmentComponent} from "../components/ThankYouFulfillmentComponent/ThankYouFulfillmentComponent"
import AnalyticsService from "../utils/AnalyticsService"

import {GTM_EVENTS} from "../utils/GtmEvents"
import {ROUTE_PATHS} from "../utils/routes"
import {isEdgeOrIE} from "../utils/Helpers"

import {VirtualCardApi, OfferApi, SubmissionApi} from "../api"

export const Status = ({history}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.STATUS);
  })
  if (!state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  const fulfillmentType = state.offer.FulfillmentType.toUpperCase()
  const offerHeaderImageUrl = state.offer.HeaderImageUrl
  const offerHeaderImageType = state.offer.HeaderImageType
  const offerHeaderImageBytes = state.offer.HeaderImageBytes
  const offerLineOneText = state.offer.StatusLineOne
  const offerLineTwoText = state.offer.StatusLineTwo
  const offerStatusQuestions = state.offer.StatusQuestions
  const offerStatusFooter = state.offer.StatusFooter
  let thankYouLineOneText = state.offer.ThankYouLineOne
  let thankYouLineTwoText = state.offer.ThankYouLineTwo
  const hidePromoCode = state.offer.HidePromoCode

  const iSubmission = state.iSubmission

  const link = iSubmission.offerFields.find((field) => field.key === 'AwardLink')
  const trackingId = iSubmission.trackingId
  const submitId = iSubmission.SubmitId
  const customerStatus = iSubmission.offerFields.find((field) => field.key === 'CustomerStatus')

  let rewardCodes = []
  let selectedItem = ""
  if (fulfillmentType === "REWARDCODE") {
    const rewardCodeField = iSubmission.offerFields.filter((field) => field.key === 'RewardCode')
    if (rewardCodeField) {
      rewardCodeField.forEach((field) => rewardCodes.push(field.value))
    }

    const selectedItemField = iSubmission.offerFields.find((field) => field.key === 'SelectedItem')
    if (selectedItemField) {
      selectedItem = selectedItemField.value
    }

    const choice = state.offer.Choices.find((f) => f.Description.toLowerCase() === selectedItem.toLowerCase())
    if (choice && thankYouLineOneText) {
      thankYouLineOneText = thankYouLineOneText.replace(/{selectedItem}/gi, selectedItem)
      thankYouLineOneText = thankYouLineOneText.replace(/{externalLinkUrl}/gi, choice.ExternalUrl)
    }
    if (choice && thankYouLineTwoText) {
      thankYouLineTwoText = thankYouLineTwoText.replace(/{selectedItem}/gi, selectedItem)
      thankYouLineTwoText = thankYouLineTwoText.replace(/{externalLinkUrl}/gi, choice.ExternalUrl)
    }
  }

  let debitCardDisbursedId = 0
    if (fulfillmentType === "METAVIRTUALCARD") {
        const debitCardDisbursedIdField = iSubmission.offerFields.find((field) => field.key === 'DebitCardDisbursedID')      
        if (debitCardDisbursedIdField) {
            debitCardDisbursedId = Number(debitCardDisbursedIdField.value)
        }
    }

  const onOpenLink = async (link) => {
    OfferApi.insertReportingLogEntry(state, "RewardLinkClicked")
    window.open(link, "_blank")
  }

  let isLoadingActivation = false
  const handleActivateCard = async (values, {setSubmitting, setStatus}) => {
    try {
      isLoadingActivation = true

      let iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, submitId)
      debitCardDisbursedId = await VirtualCardApi.activateVirtualCard(iSubmission.SubmitId, state.cultureId)

      if (debitCardDisbursedId === 0) {
        // Submission was denied - probably due to household limit
        // Need to refresh offer info which includes text displayed on this page.
        const offer = await OfferApi.getOfferByClaimCode(state.offer.ClaimCode, state.user.phoneNumber, isEdgeOrIE(), state.cultureId)
        dispatch({type: "SETOFFER", payload: offer})
      }

      // Refresh the iSubmission after activating the card with Meta
      iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, submitId)
      dispatch({type: "SETISUBMISSION", payload: iSubmission})

      if (fulfillmentType === "METAVIRTUALCARD") {
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.META_CARD_ACTIVATED, category: "MetaActivateCard", action: "Upload_Site",  label: "Submit"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "MetaActivateCard", {event: GTM_EVENTS.META_CARD_ACTIVATED})
      } else if (fulfillmentType === "CITIVIRTUALCARD") {
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.CITI_CARD_ACTIVATED, category: "CitiActivateCard", action: "Upload_Site",  label: "Submit"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "CitiActivateCard", {event: GTM_EVENTS.CITI_CARD_ACTIVATED})
      }

      if (debitCardDisbursedId > 0) {
        const cardInfo = await VirtualCardApi.getVirtualCardInfo(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
        if (fulfillmentType === "METAVIRTUALCARD") {
          const metaCardTransactions = await VirtualCardApi.getVirtualCardTransactions(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
          dispatch({type: "SETMETAVIRTUALCARD", payload: {info: cardInfo, transactions: metaCardTransactions}})

          history.push(ROUTE_PATHS.META_VIRTUAL_CARD)
        }
      }

      isLoadingActivation = false
      setSubmitting(false)
    } catch (err) {
      isLoadingActivation = false
      setSubmitting(false)
      setStatus(err.message)
    }
  }

  const handleViewCard = async (values, {setSubmitting, setStatus}) => {
    try {
        isLoadingActivation = true

      if (fulfillmentType === "METAVIRTUALCARD") {
        const cardInfo = await VirtualCardApi.getVirtualCardInfo(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
        const metaCardTransactions = await VirtualCardApi.getVirtualCardTransactions(iSubmission.SubmitId, debitCardDisbursedId, state.cultureId)
        dispatch({type: "SETMETAVIRTUALCARD", payload: {info: cardInfo, transactions: metaCardTransactions}})
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.META_CARD_VIEWED, category: "MetaViewCard", action: "Upload_Site",  label: "Submit"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "MetaViewCard", {event: GTM_EVENTS.META_CARD_VIEWED})
        history.push(ROUTE_PATHS.META_VIRTUAL_CARD)
        window.open(cardInfo.CardholderUrl)
      } else if (fulfillmentType === "CITIVIRTUALCARD") {
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.CITI_CARD_VIEWED, category: "MetaViewCard", action: "Upload_Site",  label: "Submit"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "CitiViewCard", {event: GTM_EVENTS.CITI_CARD_VIEWED})
      }

      isLoadingActivation = false
      setSubmitting(false)
    } catch (err) {
      isLoadingActivation = false
      setSubmitting(false)
      setStatus(language.status.ERROR_VIRTUAL_CARD)
    }
  }

  return (
    <section>
      <div className="container pb-2 layout-purple" style={{ overflow: "hidden" }}>
        <MediaQuery query="(min-width: 1450px)">
          <LeftMagnetSwarm />
          <RightMagnetSwarm />
        </MediaQuery>
        <MediaQuery query="(max-width: 1449px)">
          <MediaQuery query="(min-width: 992px)">
            <LeftNarrowMagnetSwarm />
            <RightNarrowMagnetSwarm />
          </MediaQuery>
        </MediaQuery>
        <div className="row">
          <div className="col">
            <FoundPromotionComponent offerLineOne={offerLineOneText} offerLineTwo={offerLineTwoText} offerHeaderImageUrl={offerHeaderImageUrl} offerHeaderImageType={offerHeaderImageType} offerHeaderImageBytes={offerHeaderImageBytes} />
          </div>
        </div>
        {fulfillmentType === "REWARDCODE" &&
          <RewardCodeFulfillmentComponent hidePromoCode={hidePromoCode} onOpenLink={onOpenLink} selectedItem={selectedItem} rewardCodes={rewardCodes} thankYouLineOneText={thankYouLineOneText} thankYouLineTwoText={thankYouLineTwoText} />}
        {fulfillmentType === "INCOMMVIRTUALCARD" &&
          <InCommFulfillmentComponent thankYouLineOneText={thankYouLineOneText} onOpenLink={onOpenLink} link={link.value} />}
        {(fulfillmentType === "METAVIRTUALCARD" || fulfillmentType === "CITIVIRTUALCARD") &&
          <VirtualCardFulfillmentComponent customerStatus={customerStatus.value} thankYouLineOneText={thankYouLineOneText} handleActivateCard={handleActivateCard} handleViewCard={handleViewCard} isLoadingActivation={isLoadingActivation} debitCardDisbursedId={debitCardDisbursedId} />}
        {fulfillmentType === "THANKYOU" &&
          <ThankYouFulfillmentComponent thankYouLineOneText={thankYouLineOneText} thankYouLineTwoText={thankYouLineTwoText}/>}
        <div style={{"textAlign": "center"}} className="mt-3">
          {offerStatusQuestions !== null ? <div className="titleOrange24Text">{offerStatusQuestions}</div> : <div className="titleOrange24Text">{language.status.footer.QUESTIONS}</div>}
          {offerStatusFooter.FooterLineOne !== null ? <div className="whiteTextSmall">{offerStatusFooter.FooterLineOne}</div> : <div className="whiteTextSmall">{language.status.footer.LINE_ONE}</div>}
          {offerStatusFooter.FooterLineTwo !== null ? <div className="whiteTextSmall">{offerStatusFooter.FooterLineTwo}</div> : <div className="whiteTextSmall">{language.status.footer.LINE_TWO}</div>}
          {offerStatusFooter.FooterLineThree !== null ? <div className="whiteTextSmall">{offerStatusFooter.FooterLineThree}</div> : <div className="whiteTextSmall">{language.status.footer.LINE_THREE}</div>}
          <div className="whiteTextSmall" style={{ marginTop: "10px", marginBottom: "10px" }}>{language.status.footer.TRACKING} </div>
          <div className="titleWhite24Text">{trackingId}</div>
        </div>
      </div>
    </section>
  )
}

Status.requiresAuth = true

Status.propTypes = {
  history: PropTypes.object.isRequired
}
