import React from "react"
import MediaQuery from "react-responsive"

export const LandingImage = () => {
  return (
    <MediaQuery query="(min-width: 992px)">
      <div className="col-8">
        <img alt="Shiny Happy People" src="images/24199.png" style={{paddingRight: "1rem", maxWidth: "100%"}} />
      </div>
    </MediaQuery>
  )
}
