import React, {Component} from 'react'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import {loadReCaptcha} from 'react-recaptcha-v3'
import * as Sentry from '@sentry/browser'

import {ROUTE_PATHS} from './utils/routes'

import {LayoutComponent, ScrollToTopComponent} from './components'
import {Home, AuthenticatedRoute, Choose, OfferOver, Offers, Promotion, Register, Status, VerificationCode, Eligibility, MetaVirtualCard, Faq, Upload, UploadSteps} from './pages'
import {CcpaApi} from './api'
import {AppProvider, LanguageProvider} from './components/context'
import {setLocalDnsValue, getLocalDnsValue, CcpaComponent} from './utils/CcpaFunctions'

class App extends Component {
  async componentDidMount() {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3)
    Sentry.init({dsn: process.env.REACT_APP_SENTRY_PUBLIC_DSN, environment: process.env.REACT_APP_ENVIRONMENT})

    window.addEventListener("message",
      function(event) {
        const tMobileDnsOrigin = process.env.REACT_APP_TMO_DNS_PAGE.replace("/dns", "") // Remove the DNS route param
        if (event.origin !== tMobileDnsOrigin) {
          return
        }

        setLocalDnsValue(event.data)
      }
    )

    function removeJS(filename) {
      let tags = document.getElementsByTagName('script')
      for (let i = tags.length; i >= 0; i--) { //search backwards within nodelist for matching elements to remove
        if (tags[i] && tags[i].getAttribute('src') !== null && tags[i].getAttribute('src').indexOf(filename) !== -1) {
          tags[i].parentNode.removeChild(tags[i]) //remove element by calling parentNode.removeChild()
        }
      }
    }

    const dnsValue = getLocalDnsValue()
    if (dnsValue === 'DoNotSell') {
      // Get black list
      const blackList = await CcpaApi.getBlackList()
      // Loop through black list and remove scripts
      blackList.forEach(element => {
        removeJS(element)
      })
    }
  }

  render() {
    return (
      <div className="App">
        <AppProvider>
          <LanguageProvider>
            <BrowserRouter>
              <ScrollToTopComponent>
                <CcpaComponent>
                  <LayoutComponent>
                    <Switch>
                      <AuthenticatedRoute exact path={ROUTE_PATHS.PROMOTION} component={Promotion} />
                      <AuthenticatedRoute exact path={ROUTE_PATHS.REGISTER} component={Register} />
                      <AuthenticatedRoute exact path={ROUTE_PATHS.CHOOSE} component={Choose} />
                      <AuthenticatedRoute exact path={ROUTE_PATHS.STATUS} component={Status} />
                      <AuthenticatedRoute exact path={ROUTE_PATHS.META_VIRTUAL_CARD} component={MetaVirtualCard} />
                      <Route exact path={ROUTE_PATHS.HOME} component={Home} />
                      <Route exact path={ROUTE_PATHS.VERIFICATION_CODE} component={VerificationCode} />
                      <Route exact path={ROUTE_PATHS.OFFERS} component={Offers} />
                      <Route exact path={ROUTE_PATHS.OFFER_OVER} component={OfferOver} />
                      <Route exact path={ROUTE_PATHS.ELIGIBILITY} component={Eligibility}/>
                      <Route exact path={ROUTE_PATHS.UPLOAD} component={Upload}/>
                      <Route exact path={ROUTE_PATHS.UPLOAD_STEP} component={UploadSteps}/>
                      <Route exact path={ROUTE_PATHS.FAQ} component={Faq}/>
                    </Switch>
                  </LayoutComponent>
                </CcpaComponent>
              </ScrollToTopComponent>
            </BrowserRouter>
          </LanguageProvider>
        </AppProvider>
      </div>
    )
  }
}

export default App
