export const faqData = [
    {
        "title": "What fees are covered for reimbursement?",
        "content": "Early Termination Fees (ETFs) on Home Internet plans and Small Business Internet plans, including ETFs related to bundle breakage caused by cancellation of an internet line.",
        "order": 1
    },
    {
        "title": "What fees are not covered for reimbursement?",
        "content": "Any fees in addition to Early Termination Fees due to contract breakage are not covered by this offer, such as (but not limited to): construction costs, device non-return fees or device damage fees, remaining balance on financed devices, late fees, missed payment, bounced checks, delinquent accounts, charge-offs.",
        "order": 2
    },
    {
        "title": "What is the maximum reimbursement I can receive?",
        "content": "The maximum reimbursement amount for this promotion is $750 per submission, to be paid out via Virtual Prepaid Mastercard Card.",
        "order": 3
    },
];