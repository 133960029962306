import React, {useEffect, useContext, useState} from 'react'
import PropTypes from "prop-types"

import {AppContext, LanguageContext} from "../components/context"
import {StandardGetStartedComponent} from "../components"

import {ROUTE_PATHS} from "../utils/routes"
import {GTM_EVENTS} from "../utils/GtmEvents"
import {AuthApi, OfferApi, PromoAdminApi, SubmissionApi, ValidationApi} from "../api"
import {verifyReCaptchaV3} from "../utils/Helpers"
import AnalyticsService from "../utils/AnalyticsService"

import '../css/Layout.css'
import { MaintenanceComponent } from '../components/MaintenanceComponent'

export const Home = ({location, history}) => {
  const {state, dispatch} = useContext(AppContext)
  // eslint-disable-next-line
  const {language} = useContext(LanguageContext)

  const isIE = false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isEdgeOrIE = isIE || isEdge

  const searchParams = new URLSearchParams(location.search)
  const claimCode = searchParams.get("claimCode")
  const [inMaintenanceMode, setInMaintenanceMode] = useState(false)
  const [maintenanceText, setMaintenanceText] = useState("")

  useEffect(() => {
    async function getControlOffer() {
      if (state.controlOffer.StateList === undefined) {
        const controlOffer = await PromoAdminApi.getOffer(process.env.REACT_APP_CONTROL_OFFER_ID, "", "", isEdgeOrIE, state.cultureId)
        dispatch({type: "SETCONTROLOFFER", payload: controlOffer})
      }
    }

    async function getMaintanceMode() {
      const result = await PromoAdminApi.getClientAsset("MaintenanceFlag")
      const maintenanceText = await PromoAdminApi.getClientAsset("MaintenanceText")

      if (result.Notes.toLowerCase() === "true") {
        setMaintenanceText(maintenanceText.Notes)
        setInMaintenanceMode(true)
        return
      }
    }

    getControlOffer()
    getMaintanceMode()
      .catch(() => {
        setMaintenanceText("")
        setInMaintenanceMode(true)
      })

  }, [state.controlOffer.StateList, dispatch, claimCode, history, isEdgeOrIE, state.cultureId])

  const submitLoginForm = async (values, {setSubmitting, setStatus}) => {
    let offerId = 0
    const phoneNumber = values.phoneNumber.replace(/\D/g, '').trim()
    const firstName = values.firstName.trim()
    const lastName = values.lastName.trim()

    const user = {phoneNumber, firstName, lastName}
    dispatch({type: "SETUSER", payload: user})

    if (values.recaptcha) {
      await AuthApi.checkReCAPTCHA(values.recaptcha, state.cultureId)
    } else {
      try {
        const isReCaptchaV3Valid = await verifyReCaptchaV3(state.reCaptchaV3LoginToken)
        dispatch({type: "SETISRECAPTCHAV3VALID", payload: isReCaptchaV3Valid})
        if (!isReCaptchaV3Valid) {
          setSubmitting(false)
          return
        }
      } catch (error) {
        setStatus(error.message)
        setSubmitting(false)
        return
      }
    }

    if (claimCode) {
      try {
        let offer = await OfferApi.getOfferByClaimCode(claimCode, phoneNumber, isEdgeOrIE, state.cultureId)
        offer = {...offer, ClaimCode: claimCode}
        dispatch({type: "SETOFFER", payload: offer})
        offerId = offer.OfferId
      } catch (error) {
        setStatus(error.message)
        setSubmitting(false)
        return
      }
    }

    if (offerId === 0) {
      try {
        const response = await ValidationApi.sendVerificationCode(phoneNumber, state.cultureId)
        if (response.StatusCode === "0") {
          //create variable to hold current cultureId for reset
          const selectedCultureId = state.cultureId
          dispatch({type: "RESETSTATE", payload: user})
          //set cultureId back to what it was before reset
          dispatch({type: "SETCULTUREID", payload: selectedCultureId})
          AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.SEND_VERIFICATION_CODE, category: "Upload_Site", action: "Submit",  label: "Home"})
          AnalyticsService.sendEvent("Upload Site", "Submit", "Home", {event: GTM_EVENTS.SEND_VERIFICATION_CODE})
          history.push(ROUTE_PATHS.VERIFICATION_CODE)
          setSubmitting(false)
          return
        }
        else {
            history.push(ROUTE_PATHS.ELIGIBILITY)
            return
        }
      } catch (error) {
        setStatus(error.message)
      }
    } else {
      let isEligible = false
      if (phoneNumber) {
        try {
          isEligible = await AuthApi.login(phoneNumber, claimCode, offerId)
        } catch (error) {
          setStatus(error.message)
        }
      }
      if (isEligible) {
        let user = {phoneNumber, firstName, lastName}
        //create variable to hold current cultureId for reset
        const selectedCultureId = state.cultureId
        dispatch({type: "RESETSTATE", payload: user})
        //set cultureId back to what it was before reset
        dispatch({type: "SETCULTUREID", payload: selectedCultureId})

        let offer = await PromoAdminApi.getOffer(offerId, phoneNumber, claimCode, isEdgeOrIE, state.cultureId)
        offer = {...offer, ClaimCode: claimCode}
        dispatch({type: "SETOFFER", payload: offer})

        // Pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route
        const iSubmission = await SubmissionApi.getISubmission(offerId, 0)
        dispatch({type: "SETISUBMISSION", payload: iSubmission})

        const customerStatus = iSubmission.offerFields.find((field) => field.key === 'CustomerStatus')

        //Redirect to promo over page regardless of the submission status
        if (offer && offer.IsOfferEnd) {
          AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.OFFER_EXPIRED, category: "Home", action: "Upload_Site",  label: "Submit"})
          AnalyticsService.sendEvent("Upload Site", "Submit", "Home", {event: GTM_EVENTS.OFFER_EXPIRED})
          history.push(ROUTE_PATHS.OFFER_OVER)
        } else if (iSubmission.SubmissionStatus === 1 || customerStatus.value.toUpperCase() === 'DENIED') {
          // 1 = Submitted
          AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.SUBMISSION_FOUND, category: "Home", action: "Upload_Site",  label: "Submit"})
          AnalyticsService.sendEvent("Upload Site", "Submit", "Home", {event: GTM_EVENTS.SUBMISSION_FOUND})
          dispatch({ type: "SETSUBMITTED", payload: true })
          history.push(ROUTE_PATHS.STATUS)
        } else {
          AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.LOGIN, category: "Home", action: "Upload_Site",  label: "Submit"})
          AnalyticsService.sendEvent("Upload Site", "Submit", "Home", {event: GTM_EVENTS.LOGIN})
          history.push(ROUTE_PATHS.PROMOTION)
        }
      }
    }
    setSubmitting(false)
  }

  return (
    <main role="main" className="container">
      {inMaintenanceMode ? <MaintenanceComponent text={maintenanceText} /> :
        <StandardGetStartedComponent submitLoginForm={submitLoginForm} claimCode={claimCode} />
      }
    </main>
  )
}

Home.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
