import React from "react"
import PropTypes from "prop-types"

import {OrangeButton} from "../components/styled"
import "../css/Layout.css"

export const InCommFulfillmentComponent = ({thankYouLineOneText, onOpenLink, link}) => {
  return (
    <section>
      <div className="row justify-content-center">
        <div className="col-7" style={{textAlign: "center", marginTop: "20px"}}>
          <div className="instructionalWhite14Text">{thankYouLineOneText}</div>
          <OrangeButton className="btn" style={{marginTop: "20px", color: "#ffffff"}} onClick={() => onOpenLink(link)}>View My Card</OrangeButton>
        </div>
      </div>
    </section>
  )
}

InCommFulfillmentComponent.propTypes = {
  thankYouLineOneText: PropTypes.string.isRequired,
  onOpenLink: PropTypes.func.isRequired,
  link: PropTypes.string
}
