import React, {useEffect, useState, useContext} from "react"
import {AppContext, LanguageContext} from "../components/context"
import {PromoAdminApi} from "../api"

import "../css/Faq.css"
import {parseClientFaq, isEmpty} from "../utils/Helpers"

import { FaqComponent } from "../components/FaqComponent"
import {ErrorMessage} from "../components/styled/ErrorMessage"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"

export const Faq = () => {
  const {dispatch, state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [isFetchingFaq, setIsFetchingFaq] = useState(false)
  const [errorMessage, setErrorMessage] = useState()
  const [parsedFaq, setParsedFaq] = useState()

  useEffect(() => {

    const fetchFaq = async () => {
      try {
        setIsFetchingFaq(true)
        const faq = !isEmpty(state.controlOffer) ? state.controlOffer.faqs : await PromoAdminApi.getClientFaqs(state.cultureId)
        setParsedFaq(parseClientFaq(faq))

      } catch (error) {
        setErrorMessage(language.faq.ERROR_MESSAGE)
      } finally {
        setIsFetchingFaq(false)
      }
    }

    fetchFaq()
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.FAQ)
  }, [dispatch, state.controlOffer, language.faq.ERROR_MESSAGE, state.cultureId])

  return (
    <div className="container" id="faq">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <h1 className="text-center">{language.faq.HEADER_TEXT}</h1>
          {isFetchingFaq && <div className="text-center">{language.general.LOADING}</div>}
          {errorMessage && <ErrorMessage className="text-center">{errorMessage}</ErrorMessage>}
          {!isEmpty(parsedFaq) && parsedFaq.map((header) =>
            <FaqComponent key={header.header} questions={header.questions}/>) }
        </div>
      </div>
    </div>)
}
