import React from "react"
import MediaQuery from "react-responsive"
import PropTypes from "prop-types"
import {SizeMe} from 'react-sizeme'

import classnames from "classnames"
import {createMarkup} from "../utils/Helpers"
import {BigOrangeTextWithoutMedia, BigWhiteTextWithoutMedia} from "../components"
import "../css/Layout.css"

export const FoundPromotionComponent = ({offerLineOne, offerLineTwo, offerHeaderImageUrl, offerHeaderImageType, offerHeaderImageBytes}) => {
  const isIE = false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isEdgeOrIE = isIE || isEdge

  let lineStyle = classnames({
    baseLineStyle: true,
    oneLineStyle: offerLineTwo ? false : true,
    twoLinesStyle: offerLineTwo
  })

  let lineStyleSmall = classnames({
    baseLineStyle: true,
    oneLineStyleSmall: offerLineTwo ? false : true,
    twoLinesStyleSmall: offerLineTwo
  })

  let lineStyleMedium = classnames({
    baseLineStyle: true,
    oneLineStyleMedium: offerLineTwo ? false : true,
    twoLinesStyleMedium: offerLineTwo
  })

  let lineStyleTall = classnames({
    baseLineStyle: true,
    oneLineStyleTall: offerLineTwo ? false : true,
    twoLinesStyleTall: offerLineTwo
  })

  return (
    <SizeMe monitorHeight>
      {({ size }) =>
        <section>
          <div className="container">
            <div className="row">
              <div className="col layout-lightpurple">
                {size.width < 496 &&
                  <div>
                    <pre><BigOrangeTextWithoutMedia className={lineStyleSmall}><p dangerouslySetInnerHTML={createMarkup(offerLineOne)}></p></BigOrangeTextWithoutMedia></pre>
                    <pre><BigWhiteTextWithoutMedia className={lineStyleSmall}><p dangerouslySetInnerHTML={createMarkup(offerLineTwo)}></p></BigWhiteTextWithoutMedia></pre>
                  </div>
                }
                {size.height >= 285 && size.width >= 496 &&
                  <div>
                    <pre><BigOrangeTextWithoutMedia className={lineStyleTall}><p dangerouslySetInnerHTML={createMarkup(offerLineOne)}></p></BigOrangeTextWithoutMedia></pre>
                    <pre><BigWhiteTextWithoutMedia className={lineStyleTall}><p dangerouslySetInnerHTML={createMarkup(offerLineTwo)}></p></BigWhiteTextWithoutMedia></pre>
                  </div>
                }
                {size.height < 285 && size.width >= 496 && size.width < 930 &&
                  <div>
                    <pre><BigOrangeTextWithoutMedia className={lineStyle}><p dangerouslySetInnerHTML={createMarkup(offerLineOne)}></p></BigOrangeTextWithoutMedia></pre>
                    <pre><BigWhiteTextWithoutMedia className={lineStyle}><p dangerouslySetInnerHTML={createMarkup(offerLineTwo)}></p></BigWhiteTextWithoutMedia></pre>
                  </div>
                }
                {size.height < 285 && size.width >= 930 &&
                  <div>
                    <pre><BigOrangeTextWithoutMedia className={lineStyleMedium}><p dangerouslySetInnerHTML={createMarkup(offerLineOne)}></p></BigOrangeTextWithoutMedia></pre>
                    <pre><BigWhiteTextWithoutMedia className={lineStyleMedium}><p dangerouslySetInnerHTML={createMarkup(offerLineTwo)}></p></BigWhiteTextWithoutMedia></pre>
                  </div>
                }
              </div>
              <MediaQuery query="(min-width: 992px)">
                <div className="col" style={{paddingLeft: "0px"}}>
                  {isEdgeOrIE &&                  
                    <img alt="Offer" src={"data:" + offerHeaderImageType + ";base64, " + offerHeaderImageBytes} style={{maxWidth: "100%"}} />}
                  

{!isEdgeOrIE &&
                  offerHeaderImageUrl !== null ?  
                  <img 
                  alt="Offer"
                  src={{offerHeaderImageUrl}}
                  onError={(event) => event.target.style.display = 'none'}
                  style={{maxWidth: "100%"}}
                /> 
                          : null} 
                                            
                </div>
              </MediaQuery>
            </div>
          </div>
        </section>
      }
    </SizeMe>
  )
}

FoundPromotionComponent.propTypes = {
  offerLineOne: PropTypes.string.isRequired,
  offerLineTwo: PropTypes.string,
  offerHeaderImageUrl: PropTypes.string,
  offerHeaderImageType: PropTypes.string,
  offerHeaderImageBytes: PropTypes.string
}
