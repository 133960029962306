export const ROUTE_PATHS = {
  HOME: '/',
  OFFER_OVER: '/offerover',
  VERIFICATION_CODE: '/verificationcode',
  OFFERS: '/offers',
  PROMOTION: "/promotion",
  REGISTER: "/register",
  CHOOSE: "/choose",
  STATUS: "/status",
  ELIGIBILITY: "/eligibility",
  META_VIRTUAL_CARD: "/virtualcard",
  UPLOAD: "/upload",
  UPLOAD_STEP: "/uploadsteps",
  FAQ: "/faq"
}
