import React, {useContext} from "react"
import PropTypes from "prop-types"
import {Formik, Form} from "formik"
import { LanguageContext, AppContext} from "../context"

import {LoadingComponent} from ".."
import {OrangeButton, ErrorMessage} from "../styled"

export const VirtualCardActivateForm = ({handleSubmit, isLoadingActivation}) => {
  const { language } = useContext(LanguageContext)
  const { state } = useContext(AppContext)

  const offerVirtualCard = state.offer.StatusVirtualCard

  return (
    <>
      <Formik onSubmit={handleSubmit}>
        {({status, isSubmitting}) => (
          <Form>
            <div className="mb-4">
              {offerVirtualCard.Instructions !== null ? <h6 className="instructionalWhite14Text" style={{ marginTop: "10px", marginBottom: "10px" }}>{offerVirtualCard.Instructions}</h6>
                :<h6 className="instructionalWhite14Text" style={{ marginTop: "10px", marginBottom: "10px" }}>{language.virtualCard.INSTRUCTION}</h6>}
              <div style={{textAlign: 'center'}}>
                {isLoadingActivation || isSubmitting ?
                  <LoadingComponent className="mb-3" isChatSpinner={false} /> :
                    (offerVirtualCard.CardButton !== null ? <OrangeButton type="submit" className="btn" primary disabled={isSubmitting}>{offerVirtualCard.CardButton}</OrangeButton>
                        : <OrangeButton type="submit" className="btn" primary disabled={isSubmitting}>{language.virtualCard.ACTIVATE_CARD}</OrangeButton>)}
                {status && <ErrorMessage>{status}</ErrorMessage>}
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

VirtualCardActivateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoadingActivation: PropTypes.bool
}
