import styled from 'styled-components'

export const WhiteText = styled.p`
  color: #ffffff;
  font-size: large;
  margin-bottom: 5em;
`

export const BigOrangeText = styled.div`
  color: #f89e00;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
  letter-spacing: 1px;

  @media (min-width: 1200px) {
    font-size: 58px;
    line-height: 58px;
  }
`

export const BigWhiteText = styled.div`
  color: #ffffff;
  font-size: 42px;
  font-family: 'TTNorms-Bold';
  line-height: 42px;
  letter-spacing: 1px;

  @media (min-width: 1200px) {
    font-size: 58px;
    line-height: 58px;
  }
`

export const BigOrangeTextWithoutMedia = styled.div`
  color: #f89e00;
  font-family: 'TTNorms-Bold';
  letter-spacing: 1px;
`

export const BigWhiteTextWithoutMedia = styled.div`
  color: #ffffff;
  font-family: 'TTNorms-Bold';
  letter-spacing: 1px;
`

export const TinyText = styled.div`
  font-size: 8px;
  color: #dddddd;
`

export const HeaderWrapper = styled.div`
  margin: 0px auto;

  @media (max-width: 575px) {
    max-width: 500px
  }

  @media (min-width: 992px) {
    max-width: 665px;
  }

  @media (min-width: 1200px) {
    max-width: 788px;
  }
`
export const MagnetSwarm = styled.div`
  position: absolute;
  height: 20em;
  width: 25em;
  top: 200px;
  background: url("/images/magnet-swarm-whiteyellow_metro.png");
  background-repeat: no-repeat;
  background-size: cover;
`

export const RightMagnetSwarm = styled(MagnetSwarm)`
  right: 0px;
`
export const LeftMagnetSwarm = styled(MagnetSwarm)`
  left: 0px;
  transform: rotate(180deg);
`

export const RightNarrowMagnetSwarm = styled(RightMagnetSwarm)`
  width: 15em;
`
export const LeftNarrowMagnetSwarm = styled(LeftMagnetSwarm)`
  width: 15em;
`
export const UploadCriteria = styled.div`
color: darkslategray;
font-size: small;
text-align: left;
margin-bottom: 5px;
`
export const HoverText = styled.span`
  :hover{
    cursor:pointer;
  }
`

export const Question = styled.div`
  font: 25px;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 2px;
  display: flex;
  font-weight: normal;
  background: #f89e00;
  padding: 0.75em 1.12em;
  align-items: center;
`