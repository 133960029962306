import React from "react"
import PropTypes from "prop-types"

import {createMarkup} from "../utils/Helpers"

export const FaqComponent = ({questions}) => {
  return (
    <>
    {questions.map((questionGroup) =>
      <div className="item" key={questionGroup.ItemText}>
        <h4>{questionGroup.ItemText}</h4>
        <div dangerouslySetInnerHTML={createMarkup(questionGroup.DetailText)}></div>
      </div>
    )}
    </>
  )
}

FaqComponent.propTypes = {
  questions: PropTypes.array.isRequired
}
