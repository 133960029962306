import React from 'react'
import PropTypes from "prop-types"

import {ErrorMessage} from "../components/styled"

import "../css/RadioToggle.css"

export const RadioButtonComponent = ({error, touched, children}) => {
  return (
    <div>
      <div className="radio-toggle-fieldset">
        {children}
      </div>
      {touched && <ErrorMessage error={error} />}
    </div>
  )
}

RadioButtonComponent.propTypes = {
  error: PropTypes.string,
  children: PropTypes.array.isRequired,
  touched: PropTypes.bool
}
