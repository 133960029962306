import React, {useContext} from "react"
import moment from "moment"

import {AppContext, LanguageContext} from "../context"

export const MetaCardTransactionsForm = () => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  const cardInformation = state.metaVirtualCard.info
  const cardTransactions = state.metaVirtualCard.transactions

  return (
    <form noValidate>
      <div className="d-flex flex-column justify-content-center">
        <div className="mb-3" style={{
          background: 'lightgrey',
          padding: '10px',
          fontWeight: '700',
          paddingLeft: '5%',
          textTransform: 'uppercase'
        }}>
          {language.virtualCard.CURRENT_BALANCE}: ${cardInformation.TotalAmount}
        </div>
        <div className="container container-padded container-fluid">
          <div>
            <div className="col-xs-12 text-center title-pad20">
              <h3>{language.virtualCard.HISTORY}</h3>
            </div>
            <div className="col-md-12 col-md-offset-1 col-sm-12 col-xs-12 no-pad-xs">
              <table className="table table-striped tbl-style">
                <thead>
                  <tr className="title">
                    <th>{language.virtualCard.DATE}</th>
                    <th>{language.virtualCard.DESCRIPTION}</th>
                    <th>{language.virtualCard.TYPE}</th>
                    <th>{language.virtualCard.AMOUNT}</th>
                  </tr>
                </thead>
                <tbody>
                  {cardTransactions.map((st, i) => {
                    return (
                      <tr key= {`row-${i}`} className="offer">
                        <td key= {`date-${i}`}>{moment(st.Date).format('MM-DD-YYYY HH:MM')}</td>
                        <td key= {`desc-${i}`}>{st.Description}</td>
                        <td key= {`type-${i}`}>{st.Type}</td>
                        <td key= {`amt-${i}`}>{st.Amount}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
