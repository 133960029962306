import React, {useState, useContext, useEffect} from "react"
import PropTypes from "prop-types"
import { AppContext, LanguageContext } from "../components/context"
import MediaQuery from 'react-responsive'
import { RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm, BigOrangeText } from "../components"
import {UploadForm} from "../components/forms"
import { ROUTE_PATHS } from "../utils/routes"
import { SubmissionApi } from "../api"
import AnalyticsService from "../utils/AnalyticsService"
import {GTM_EVENTS} from "../utils/GtmEvents"
import {LoadingComponent} from "../components"
import FaqUpload from "../components/FaqUploadComponent"
import { faqData } from "../data/faq";

export const UploadSteps = ({history}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.UPLOAD_STEP);
  })
  if (state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true)
      setErrorMessage("")

      if (state.files.length === 0) {
        setErrorMessage(language.errors.upload.MIN_UPLOADS)
        return
      }

      // Pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route
      const iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, 0)
      await SubmissionApi.upload(state.files, iSubmission.iSubmitId)

      const updatedISubmission = await SubmissionApi.finalizeSubmission(iSubmission, state.user, state.cultureId)

      //upload is last step
      //no need to check for additional routing
      await SubmissionApi.sendThankYouSms(iSubmission, state)
      dispatch({type: "SETISUBMISSION", payload: updatedISubmission})
      dispatch({type: "SETSUBMITTED", payload: true })
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.FILE_UPLOAD, category: "Upload", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Upload", {event: GTM_EVENTS.FILE_UPLOAD})
      history.push(ROUTE_PATHS.STATUS)

    } catch (error) {
      setErrorMessage(language.errors.GENERAL)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div>
      <section>
        <div className="container hero3">
          <MediaQuery query="(min-width: 1450px)">
            <LeftMagnetSwarm />
            <RightMagnetSwarm />
          </MediaQuery>
          <MediaQuery query="(max-width: 1449px)">
            <MediaQuery query="(min-width: 992px)">
              <LeftNarrowMagnetSwarm />
              <RightNarrowMagnetSwarm />
            </MediaQuery>
          </MediaQuery>

          <BigOrangeText style={{paddingTop: "15px"}}>{language.upload_step.HEADER_TEXT}</BigOrangeText>

          <div className="row justify-content-center" style={{textAlign: "center"}}>
            <MediaQuery query="(min-width: 992px)">
              <div className="col-6">
                <UploadForm
                  instructions={state.offer.UploadInstructions}
                  onSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  errorMessage={errorMessage}></UploadForm>
                {isSubmitting && <LoadingComponent className="mb-3" isChatSpinner={false} />}
              </div>
              <div className="col-6">
                <br />
                <div className="container content-box-metro" id="faq">
                  <div className="item">
                    {faqData.map((faq) => (
                      <FaqUpload
                        key={faq.order}
                        title={faq.title}
                        content={faq.content}
                      />
                    ))
                  }
                    
                  </div>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery query="(max-width: 991px)">
              <div className="col">
                <BigOrangeText style={{paddingTop: "15px"}}>{language.upload.HEADER_TEXT}</BigOrangeText>
                <UploadForm
                  instructions={state.offer.UploadInstructions}
                  onSubmit={handleSubmit}
                  isSubmitting={isSubmitting}
                  errorMessage={errorMessage}></UploadForm>
                {isSubmitting && <LoadingComponent className="mb-3" isChatSpinner={false} />}
              </div>
            </MediaQuery>
          </div>
        </div>
      </section>
    </div>
  )
}

UploadSteps.propTypes = {
  history: PropTypes.object
}
