import React, { useContext } from "react"
import PropTypes from "prop-types"

import {VirtualCardActivateForm, VirtualCardViewForm} from "./forms"

import "../css/Layout.css"
import { AppContext } from "./context"

export const VirtualCardFulfillmentComponent = ({customerStatus, thankYouLineOneText, handleActivateCard, handleViewCard, debitCardDisbursedId, isLoadingActivation}) => {
    const { state } = useContext(AppContext)
    const fulfillmentType = state.offer.FulfillmentType.toUpperCase()

    const isPendingVirtualCard = fulfillmentType === "CITIVIRTUALCARD" ? true : false

    return (
    <section>
      <div className="row justify-content-center">
        <div className="col-7" style={{textAlign: "center", marginTop: "20px"}}>
          <div className="instructionalWhite14Text">{thankYouLineOneText}</div>
                    {debitCardDisbursedId === 0 && customerStatus && customerStatus.toUpperCase() !== 'DENIED' && customerStatus.toUpperCase() !== 'ELIGIBLE' && !isPendingVirtualCard &&
            <VirtualCardActivateForm handleSubmit={handleActivateCard} isLoadingActivation={isLoadingActivation} />}
                    {debitCardDisbursedId > 0 && !isPendingVirtualCard && <VirtualCardViewForm handleSubmit={handleViewCard} isLoadingActivation={isLoadingActivation} isPendingVirtualCard={isPendingVirtualCard}/>}
                    {isPendingVirtualCard && <VirtualCardViewForm handleSubmit={handleViewCard} isLoadingActivation={isLoadingActivation} isPendingVirtualCard={isPendingVirtualCard}/>}
        </div>
      </div>
    </section>
  )
}

VirtualCardFulfillmentComponent.propTypes = {
  handleActivateCard: PropTypes.func.isRequired,
  handleViewCard: PropTypes.func.isRequired,
  debitCardDisbursedId: PropTypes.number,
  isLoadingActivation: PropTypes.bool,
  customerStatus: PropTypes.string
}
