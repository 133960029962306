import React, { useState, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import { AppContext, LanguageContext } from "../components/context"
import MediaQuery from 'react-responsive'
import { RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm, BigOrangeText } from "../components"
import { UploadForm } from "../components/forms"
import { ROUTE_PATHS } from "../utils/routes"
import { SubmissionApi, OfferApi } from "../api"
import AnalyticsService from "../utils/AnalyticsService"
import { GTM_EVENTS } from "../utils/GtmEvents"
import { LoadingComponent } from "../components"
import { isEdgeOrIE } from "../utils/Helpers"

export const Upload = ({ history }) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [isSubmitting, setIsSubmitting] = useState()
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.UPLOAD);
  })
  if (state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  const getISubmission = () => {
    return {
      Address1: history.location.state.address1,
      Address2: history.location.state.address2,
      City: history.location.state.city,
      isResend: true,
      PostalCode: history.location.state.postalCode,
      firstName: state.user.firstName,
      lastName: state.user.lastName,
      phone: state.user.phoneNumber,
      offerFields: "268669",
      cultureId: state.cultureId,
      iSubmitId: history.location.state.iSubmitID,
      claimCode: history.location.state.claimCode
    }
  }

  const submitOfferIdAction = async (offerId) => {
    /* If offerId, pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route */
    const iSubmission = offerId ? await SubmissionApi.getISubmission(offerId, 0) : getISubmission()    
    await SubmissionApi.upload(state.files, history.location.state.iSubmitID)
    const UpdateDenied = offerId ? "Normal":await SubmissionApi.UpdateDeniedSubmission(getISubmission().iSubmitId, getISubmission().offerFields)
    dispatch({type: "DeniedStatus", payload: UpdateDenied})
    //Updates Submission to Status 2 and Finalize Process
    const updatedISubmission = await SubmissionApi.finalizeSubmission(iSubmission, state.user, state.cultureId)
    //Sends SMS
    await SubmissionApi.sendThankYouSms(iSubmission, state)
    /* If no offerId passed in, it means that it is a resubmit, so offer is still not set*/
    if (!offerId) {
      //SET OFFER HERE from PA Values      
      let offer = await OfferApi.getOfferByClaimCode(iSubmission.claimCode, iSubmission.phone, isEdgeOrIE(), iSubmission.cultureId)
      offer = {...offer, ClaimCode: iSubmission.claimCode}
      dispatch({type: "SETOFFER", payload: offer})
    }
    //Sets Values from Updated Submission
    dispatch({type: "SETISUBMISSION", payload: updatedISubmission})
    dispatch({type: "SETSUBMITTED", payload: true })
    AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.FILE_UPLOAD, category: "Upload", action: "Upload_Site",  label: "Submit"})
    AnalyticsService.sendEvent("Upload Site", "Submit", "Upload", {event: GTM_EVENTS.FILE_UPLOAD})
    //Goes to Thank u Page
    return history.push(ROUTE_PATHS.STATUS)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      setIsSubmitting(true)
      setErrorMessage("")

      if (state.files.length === 0) {
        setErrorMessage(language.errors.upload.MIN_UPLOADS)
        return
      }

      submitOfferIdAction(state.offer.OfferId)

    } catch (error) {
      setErrorMessage(language.errors.GENERAL)
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div>
      <section>
        <div className="container hero3">
          <MediaQuery query="(min-width: 1450px)">
            <LeftMagnetSwarm />
            <RightMagnetSwarm />
          </MediaQuery>
          <MediaQuery query="(max-width: 1449px)">
            <MediaQuery query="(min-width: 992px)">
              <LeftNarrowMagnetSwarm />
              <RightNarrowMagnetSwarm />
            </MediaQuery>
          </MediaQuery>
          <div className="row justify-content-center" style={{textAlign: "center"}}>
            <MediaQuery query="(min-width: 992px)">
              <div className="col-6">
                <BigOrangeText style={{paddingTop: "15px"}}>{language.upload.HEADER_TEXT}</BigOrangeText>
                <UploadForm
                  instructions={state.offer.UploadInstructions}
                  onSubmit={handleSubmit}
                  isSubmitting={isSubmitting?isSubmitting:false}
                  errorMessage={errorMessage}></UploadForm>
                {isSubmitting && <LoadingComponent className="mb-3" isChatSpinner={false} />}
              </div>
            </MediaQuery>
            <MediaQuery query="(max-width: 991px)">
              <div className="col">
                <BigOrangeText style={{paddingTop: "15px"}}>{language.upload.HEADER_TEXT}</BigOrangeText>
                <UploadForm
                  instructions={state.offer.UploadInstructions}
                  onSubmit={handleSubmit}
                  isSubmitting={isSubmitting?isSubmitting:false}
                  errorMessage={errorMessage}></UploadForm>
                {isSubmitting && <LoadingComponent className="mb-3" isChatSpinner={false} />}
              </div>
            </MediaQuery>
          </div>
        </div>
      </section>
    </div>
  )
}

Upload.propTypes = {
  history: PropTypes.object
}
