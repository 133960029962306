import {Component} from "react"
import PropTypes from "prop-types"

export default class Tab extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.node
    ]).isRequired
  }

  render() {
    return this.props.children
  }
}
