import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'
import {Languages} from "../components/context/data/Languages"

import {PromoAdminApi} from "."

class OfferApi extends BaseApi {
  async getOfferByClaimCode(claimCode, phoneNumber, isEdgeOrIE, cultureId) {
    try {
      const response = await this.post(`/offer?cultureId=${cultureId}`, {
        ClaimCode: claimCode,
        PhoneNumber: phoneNumber
      })
      const offer = response.data
      if (isEdgeOrIE) {
        const offerHeaderImage = await PromoAdminApi.getImage(offer.OfferId, offer.HeaderImageKeyword)
        const offerWithHeaderImage = {...offer, HeaderImageBytes: offerHeaderImage.data, HeaderImageType: offerHeaderImage.headers["content-type"]}
        return offerWithHeaderImage
      }
      return offer
    } catch (error) {
      const newError = cultureId === 2 ? Languages.sp.home.ERROR_PHONE : Languages.en.home.ERROR_PHONE
      throw new Error(newError)
    }
  }

  async getOffersByPhoneNumber(phoneNumber, cultureId) {
    try {
      const response = await this.get(`/offers?phoneNumber=${phoneNumber}`)
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      const newError = cultureId === 2 ? Languages.sp.errors.OFFER_PHONE : Languages.en.errors.OFFER_PHONE
      throw new Error(newError)
    }
  }

  async insertReportingLogEntry(state, logEntryType) {
    try {
      const postData = {
        PhoneNumber: state.user.phoneNumber,
        LogEntryType: logEntryType
      }
      await this.post('/createLogEntry', postData)
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  async registerJwtToken(response) {
    try {
      const token = response
      this.setAuthToken(token)
    } catch (error) {
      throw new Error(error)
    }
  }
}


export default new OfferApi()
