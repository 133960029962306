import {useEffect} from "react"
import {withRouter} from "react-router-dom"

export const ScrollToTopComponent = withRouter(({children, location: { pathname} }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children
})
