import React, {useContext, useEffect} from 'react'

import {BigOrangeText, BigWhiteText, WhiteText} from '../components'
import {AppContext, LanguageContext} from "../components/context"
import AnalyticsService from "../utils/AnalyticsService"
import { ROUTE_PATHS } from "../utils/routes"


export const OfferOver = () => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.OFFER_OVER);
  })
  return (
    <section>
      <div className="container">
        <BigOrangeText>{language.offerOver.ORANGE_TEXT}</BigOrangeText>
        <BigWhiteText>{language.offerOver.WHITE_TEXT}</BigWhiteText>
        <WhiteText>
          {state.offer.OfferOverMessage}
        </WhiteText>
      </div>
    </section>
  )
}
