import React, {useContext} from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field} from "formik"
import {ReCaptcha} from 'react-recaptcha-v3'

import {TextInput} from "../inputs"
import {OrangeButton, ErrorMessage} from "../styled"
import {LanguageContext} from "../context"

import {verifyReCaptchaV3} from "../../utils/Helpers"



export const EnterVerificationCodeForm = ({onSubmit}) => {
  const {language} = useContext(LanguageContext)

  const initialValues = {
    code: ""
  }

  const CodeSchema = Yup.object().shape({
    code: Yup.string().required(`${language.form.required.VERIFICATION}`),
  })

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={CodeSchema} onSubmit={onSubmit}>
        {({status, isSubmitting}) => (
          <Form>
            <p className="orangeTextSmall">{language.form.REQUIRED_FIELDS_INDICATOR}</p>
            <div className="row">
              <div className="form-group col-md-12">
                <Field name="code" component={TextInput} autoFocus placeholder={language.form.placeholder.VERIFICATION} type="text" />
                {status && <ErrorMessage>{language.form.validation.VERIFICATION}</ErrorMessage>}
              </div>
            </div>
            <ReCaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3} action='VerificationCode' verifyCallback={verifyReCaptchaV3} />
            <div className="mt-3 text-center">
              <OrangeButton type="submit" id="btnSubmit" className="btn" disabled={isSubmitting} primary>{language.form.button.CONFIRM_CODE}</OrangeButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

EnterVerificationCodeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
