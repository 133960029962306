import React, {useContext, useEffect} from "react"
import MediaQuery from 'react-responsive'
import PropTypes from "prop-types"

import {AppContext, LanguageContext} from "../components/context"
import {AuthApi, OfferApi, PromoAdminApi, SubmissionApi} from "../api"
import AnalyticsService from "../utils/AnalyticsService"
import {ROUTE_PATHS} from "../utils/routes"
import {RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm, BigOrangeText, OffersAvailableComponent, OffersRedeemedComponent} from "../components"

export const Offers = ({history}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const availableOffers = state.user.availableOffers
  const claimedOffers = state.user.claimedOffers

  const isIE = false || !!document.documentMode
  const isEdge = !isIE && !!window.StyleMedia
  const isEdgeOrIE = isIE || isEdge

  useEffect(() => {
      AnalyticsService.sendUniquePageview(ROUTE_PATHS.OFFERS);
  })

  const onAvailableOfferClick = async (phoneNumber, claimCode, offerId) => {
    const isAuthorized = await AuthApi.login(phoneNumber, claimCode, offerId)    
    if (isAuthorized) {
      let promoOffer = await PromoAdminApi.getOffer(offerId, phoneNumber, claimCode, isEdgeOrIE, state.cultureId)
      let getHintAddress = await SubmissionApi.getHintAddress(phoneNumber, offerId);
      promoOffer = {...promoOffer, ClaimCode: claimCode}
      dispatch({type: "SETOFFER", payload: promoOffer})
      dispatch({type: "SETHINTADDRESS", payload: getHintAddress})
      OfferApi.insertReportingLogEntry(state, "RedeemMyOffer")
      history.push(ROUTE_PATHS.PROMOTION)
    }
  }

  const onRedeemedOfferClick = async (phoneNumber, claimCode, offerId, submitId) => {    
    const isAuthorized = await AuthApi.login(phoneNumber, claimCode, offerId)
    if (isAuthorized) {
      let promoOffer = await PromoAdminApi.getOffer(offerId, phoneNumber, claimCode, isEdgeOrIE, state.cultureId)
      let getHintAddress = await SubmissionApi.getHintAddress(phoneNumber, offerId);
      promoOffer = {...promoOffer, ClaimCode: claimCode}
      dispatch({type: "SETOFFER", payload: promoOffer})
      dispatch({type: "SETHINTADDRESS", payload: getHintAddress})
      const iSubmission = await SubmissionApi.getISubmission(offerId, submitId)
      dispatch({type: "SETISUBMISSION", payload: iSubmission})

      dispatch({ type: "SETSUBMITTED", payload: true })
      history.push(ROUTE_PATHS.STATUS)
    }
  }

  return (
    <section>
      <div className="container hero3" style={{paddingTop: "40px"}}>
        <MediaQuery query="(min-width: 1450px)">
          <LeftMagnetSwarm />
          <RightMagnetSwarm />
        </MediaQuery>
        <MediaQuery query="(max-width: 1449px)">
          <MediaQuery query="(min-width: 992px)">
            <LeftNarrowMagnetSwarm />
            <RightNarrowMagnetSwarm />
          </MediaQuery>
        </MediaQuery>
        <div className="row">
          <div className="col" style={{textAlign: "center"}}>
            <BigOrangeText>{language.offers.HEADER_TEXT}</BigOrangeText>
            <div className="instructionalWhite14Text" style={{paddingTop: "20px", paddingBottom: "10px"}}>{language.offers.SELECT_OFFER}</div>
          </div>
        </div>
        <div className="row justify-content-center">
          <MediaQuery query="(min-width: 992px)">
            <div className="col-7">
              <OffersAvailableComponent offers={availableOffers} noOffersText={language.offers.NO_ACTIVE_OFFERS} onOfferClick={onAvailableOfferClick} />
              <OffersRedeemedComponent offers={claimedOffers} noOffersText={language.offers.NO_REDEEMED_OFFERS}onOfferClick={onRedeemedOfferClick} />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 991px)">
            <div className="col-7">
              <OffersAvailableComponent offers={availableOffers} noOffersText={language.offers.NO_ACTIVE_OFFERS} onOfferClick={onAvailableOfferClick} />
              <OffersRedeemedComponent offers={claimedOffers} noOffersText={language.offers.NO_REDEEMED_OFFERS} onOfferClick={onRedeemedOfferClick} />
            </div>
          </MediaQuery>
        </div>
      </div>
    </section>
  )
}

// Offers.requiresAuth = true

Offers.propTypes = {
  history: PropTypes.object.isRequired
}
