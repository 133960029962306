import React, {useContext} from "react"
import PropTypes from "prop-types"
import {LanguageContext} from "../components/context"
import {OffersRowComponent} from "."

export const OffersAvailableComponent = ({offers, noOffersText, onOfferClick}) => {
  const {language} = useContext(LanguageContext)
  return (
    <div className="container hero3">
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table myOffersTable">
              <tbody>
                <tr className="offersAvailableHeader">
                  <th className="expirationDateCol" scope="col">{language.offers.EXPIRATION}</th>
                  <th className="offerNameCol" scope="col">{language.offers.OFFER_NAME}</th>
                  <th className="offerNameCol" scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
                {offers.length === 0 && <tr className="offersAvailableRow text-center"><td /><td>{noOffersText}</td><td/><td></td></tr>}
                {offers.length > 0 &&
                  offers.map((offer) =>
                    <OffersRowComponent
                      rowClassName="offersAvailableRow"
                      linkClassName="offersAvailableLinkCol"
                      offer={offer}
                      key={offer.OfferId}
                      onOfferClick={onOfferClick}
                      linkText={language.offers.LINK_AVAILABLE} />)}
                <tr className="offersAvailableFooter">
                  <td></td><td></td><td></td><td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

OffersAvailableComponent.propTypes = {
  offers: PropTypes.array,
  noOffersText: PropTypes.string.isRequired,
  onOfferClick: PropTypes.func.isRequired
}
