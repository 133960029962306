import React from "react"
import {Redirect, Route} from "react-router-dom"

import {AuthApi} from "../api"
import {ROUTE_PATHS} from "../utils/routes"

const isAuthenticated = () => AuthApi.isAuthenticated()

export const AuthenticatedRoute = ({component, ...props}) => {
  const {requiresAuth} = component
  let route = <Route {...props} component={component} />

  if (!isAuthenticated() && requiresAuth) {
    route = <Redirect to={ROUTE_PATHS.HOME} />
  }

  return route
}

