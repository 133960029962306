import React, {useContext} from "react"
import PropTypes from "prop-types"
import * as Yup from "yup"
import {Formik, Form, Field} from "formik"
import {ReCaptcha} from 'react-recaptcha-v3'

import {TextInput, SelectInput} from "../inputs"
import {OrangeButton, ErrorMessage} from "../styled"
import {LoadingComponent} from "../../components"

import {verifyReCaptchaV3} from "../../utils/Helpers"
import {AppContext, LanguageContext} from "../context"

export const RegistrationForm = ({onSubmit}) => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const states = state.offer.StateList
  const emailField = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "email")
  const address1Field = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "address1")
  const address2Field = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "address2")
  const cityField = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "city")
  const stateField = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "state")
  const postalCodeField = state.offer.Fields.find((f) => f.FieldName.toLowerCase() === "postalcode")
  const fulfillmentType = state.offer.FulfillmentType.toUpperCase()

  const validAlphaNumericRegEx = /^([a-zA-Z0-9\s.]*$)/
  const validAddressRegEx = /^([.a-zA-Z0-9]+\s+[.a-zA-Z0-9\s]+$)/
  const validAddress2RegEx = /^([a-zA-Z0-9\s]*$)/;
  const validCityRegEx = /^([a-zA-Z\s]*$)/
  const validNumbersRegEx = /\b\d{5}\b/
  const notDefaultAddressRegEx = /^10\Wsouth\W5th\Wstreet$/i
  const notDefaultAddress2RegEx = /^10\Ws\W5th\Wst\Wste\W7$/i
  const notDefaultAddress3RegEx = /^411\Ww\Warapaho\Wrd$/i

  const address1FieldIsRequired = () => {
    return address1Field && address1Field.IsRequired
  }

  const address2FieldIsRequired = () => {
    return address2Field && address2Field.IsRequired
  }

  const cityFieldIsRequired = () => {
    return cityField && cityField.IsRequired
  }

  const postalCodeFieldIsRequired = () => {
    return postalCodeField && postalCodeField.IsRequired
  }

  const emailFieldIsRequired = () => {
    return emailField && emailField.IsRequired
  }

  const getButtonText = fulfillmentType === "CITIVIRTUALCARD" ? language.form.button.REQUEST : language.form.button.NEXT
  
  const initialValues = {
    address1: state.hintAddress != null && state.hintAddress.Address1 != null ? state.hintAddress.Address1 : "",
    address1FieldIsRequired: address1FieldIsRequired(),
    address2: state.hintAddress != null && state.hintAddress.Address2 != null ? state.hintAddress.Address2 :"" ,
    address2FieldIsRequired: address2FieldIsRequired(),
    city: state.hintAddress != null && state.hintAddress.City != null ? state.hintAddress.City : "",
    cityFieldIsRequired: cityFieldIsRequired(),
    state: state.hintAddress != null && state.hintAddress.State != null ? state.hintAddress.State : "",
    postalCode: state.hintAddress != null && state.hintAddress.PostalCode != null ? state.hintAddress.PostalCode : "",
    postalCodeFieldIsRequired: postalCodeFieldIsRequired(),
    email: "",
    emailFieldIsRequired: emailFieldIsRequired(),
  }

  const RegistrationSchema = Yup.object().shape({
    address1: Yup
      .string()
      .when("address1FieldIsRequired", {is: true, then: Yup.string().required(`${language.form.required.ADDRESS}`)})
      .test('isAddressAlphaNumeric', `${language.form.validation.ALPHA_NUMERIC}`, value => validAlphaNumericRegEx.test(value))
      .test('validAddress', `${language.form.validation.ADDRESS}`, value => validAddressRegEx.test(value))
      .test('notDefaultAddressText', 'Address Not Allowed', value => !notDefaultAddressRegEx.test(value))
      .test('notDefaultAddressText2', 'Address Not Allowed', value => !notDefaultAddress2RegEx.test(value))
      .test('notDefaultAddressText3', 'Address Not Allowed', value => !notDefaultAddress3RegEx.test(value)),
    address2: Yup
      .string()
      .when("address2FieldIsRequired", {is: true, then: Yup.string().required(`${language.form.required.ADDRESS2}`)})
      .test('isAddress2AlphaNumeric', `${language.form.validation.ALPHA_NUMERIC}`, value => validAlphaNumericRegEx.test(value))
      .test('validAddress2', `${language.form.validation.ADDRESS}`, value => validAddress2RegEx.test(value)),
    city: Yup
      .string()
      .when("cityFieldIsRequired", {is: true, then: Yup.string().required(`${language.form.required.CITY}`)})
      .min(2, `${language.form.validation.CITY}`)
      .test('isCityAlphaNumeric', `${language.form.validation.ALPHA_NUMERIC}`, value => validAlphaNumericRegEx.test(value))
      .test('validCity', `${language.form.validation.CITY}`, value => validCityRegEx.test(value)),
    postalCode: Yup
      .string()
      .when("postalCodeFieldIsRequired", {is: true, then: Yup.string().required(`${language.form.required.ZIP}`)})
      .min(5, `${language.form.validation.ZIP_LIMIT}`)
      .max(5, `${language.form.validation.ZIP_LIMIT}`)
      .matches(validNumbersRegEx, `${language.form.validation.ZIP}`),
    email: Yup
      .string()
      .when("emailFieldIsRequired", {is: true, then: Yup.string().required(`${language.form.required.EMAIL}`)})
      .email(`${language.form.validation.EMAIL}`)
  })

  const validateState = (value) => {
    let error
    if (value === "" && stateField.IsRequired) {
      error = language.form.required.STATE
    }
    return error
  }

  return (
    <Formik initialValues={initialValues} validationSchema={RegistrationSchema} onSubmit={onSubmit}>
      {({status, isSubmitting}) => (
        <Form>
          <h2 className="titleWhite24Text text-center" style={{marginTop: "1rem"}}>{language.registration.HEADER_TEXT}</h2>
          <p className="requiredFieldsText text-center">{language.form.REQUIRED_FIELDS_INDICATOR}</p>
          <div className="row" style={{marginBottom: "1rem", marginLeft: "0", marginRight: "0"}}>
            <div className="col layout-otherpurple">
              <b>
                <label className="orangeTextSmall" style={{paddingTop: "0.5rem"}}>{language.registration.NAME}:</label> <label className="whiteTextSmall">{state.user.firstName} {state.user.lastName}</label>
                <br/>
                <label className="orangeTextSmall">{language.registration.PHONE}:</label> <label className="whiteTextSmall">{state.user.phoneNumber}</label>
              </b>
            </div>
          </div>
          {address1Field && <div className="row">
            <div className="form-group col">
              <Field name="address1" type="text" component={TextInput} autoFocus placeholder={language.form.placeholder.ADDRESS}/>
            </div>
          </div>}
          {address2Field && <div className="row">
            <div className="form-group col">
              <Field name="address2" type="text" component={TextInput} placeholder={language.form.placeholder.ADDRESS2} />
            </div>
          </div>}
          {cityField && <div className="row">
            <div className="form-group col">
              <Field name="city" type="text" component={TextInput} placeholder={language.form.placeholder.CITY} />
            </div>
          </div>}
          {(stateField || postalCodeField) && <div className="row">
            {stateField && <div className="form-group col-md-6">
              <Field name="state" component={SelectInput} validate={validateState} disabled={false}>
                <option value="">{language.form.placeholder.STATE}</option>
                {states.map((state) => {
                  return (<option value={state.Abbreviation} key={state.Abbreviation}>{state.Name}</option>)
                })}
              </Field>
            </div>}
            {postalCodeField && <div className="form-group col-md-6">
              <Field name="postalCode" type="text" component={TextInput} placeholder={language.form.placeholder.ZIP} />
            </div>}
          </div>}
          {emailField && <div className="row">
            <div className="form-group col-md-12">
              <Field name="email" type="email" component={TextInput} placeholder={language.form.placeholder.EMAIL} />
            </div>
          </div>}
          <div className="mt-3" style={{textAlign: "center", paddingBottom: "1rem"}}>
            {isSubmitting ?
              <LoadingComponent className="mb-3" isChatSpinner={false} /> :
              (<OrangeButton type="submit" className="btn" primary disabled={isSubmitting}>{getButtonText}</OrangeButton>)}
            {status && <ErrorMessage>{status}</ErrorMessage>}
          </div>
          <ReCaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3} action='Register' verifyCallback={verifyReCaptchaV3} />
        </Form>)}
    </Formik>
  )
}

RegistrationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fields: PropTypes.array
}
