import React, {useContext} from "react"
import PropTypes from "prop-types"
import {Formik, Form} from "formik"

import {LanguageContext} from "../context"
import {LoadingComponent} from ".."
import {OrangeButton, ErrorMessage} from "../styled"

export const VirtualCardViewForm = ({ handleSubmit, isLoadingActivation, isPendingVirtualCard}) => {
  const {language} = useContext(LanguageContext)
  return (
    <>
      <Formik onSubmit={handleSubmit}>
        {({status, isSubmitting}) => (
          <Form>
            <div className="mb-4" style={{textAlign: 'center', marginTop: "10px"}}>
              {isLoadingActivation || isSubmitting ?
                <LoadingComponent className="mb-3" isChatSpinner={false} /> : isPendingVirtualCard ?
                    <p className="instructionalWhite14Text"><strong> {language.virtualCard.REQUEST_CARD}</strong></p> :
                (<OrangeButton type="submit" className="btn" primary disabled={isSubmitting}>{language.virtualCard.VIEW_CARD}</OrangeButton>)}
              {status && <ErrorMessage>{status}</ErrorMessage>}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

VirtualCardViewForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoadingActivation: PropTypes.bool,
  isPendingVirtualCard: PropTypes.bool
}
