import styled from 'styled-components'

export const Button = styled.button`
  border: none;
  padding: 12px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  max-width: 200px;
  width: 100%;
  cursor: pointer;
`

export const OrangeButton = styled(Button)`
  background-color: #e37501;
  color: #ffffff;
`

export const PurpleButton = styled(Button)`
  background-color: #71289a;
  color: #ffffff;
`

export const UploadButton = styled(Button)`
  border: 2px solid #e37501;
  color: #e37501;
  border-radius: 5px;
  background-color: transparent;
`
