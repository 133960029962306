import React, {useContext, useEffect} from 'react'
import PropTypes from "prop-types"

import {AppContext, LanguageContext} from "../components/context"
import {ValidationApi} from "../api"
import { OfferApi } from "../api"
import {ROUTE_PATHS} from "../utils/routes"
import {GTM_EVENTS} from "../utils/GtmEvents"
import {EnterVerificationCodeForm} from "../components/forms"
import {LandingImage} from "../components/styled"
import AnalyticsService from "../utils/AnalyticsService"
import '../css/Text.css'

export const VerificationCode = ({history}) => {
  const {state, dispatch} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  useEffect(() => {
    
    if (state.isSubmitted) {
      history.replace(ROUTE_PATHS.HOME)
    }
    // if (state.verificationCode && state.verificationCode.length > 0) {
    //   history.replace(ROUTE_PATHS.OFFERS)
    // }
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.VERIFICATION_CODE);
  }, [history, state.isSubmitted])

  const confirmCode = async (values, {setSubmitting, setStatus}) => {
    let status = "Invalid Verification Code or Code Expired"
      try {
          await ValidationApi.validateVerificationCode(state.user.phoneNumber, values.code, state.cultureId).then(
              async (response) => {
                  if (response) {
                      await OfferApi.registerJwtToken(response)
                      const offers = await OfferApi.getOffersByPhoneNumber(state.user.phoneNumber, state.cultureId)
                      const availableOffers = offers.filter((o) => o.HasSubmitted === false && o.isRejected === false)
                      let claimedOffers = offers.filter((o) => o.HasSubmitted === true)
                      const deniedOffers = offers.filter((o) => o.isRejected === true)
                      claimedOffers = [...claimedOffers, ...deniedOffers];
                      const user = { phoneNumber: state.user.phoneNumber, firstName: state.user.firstName, lastName: state.user.lastName, availableOffers: availableOffers, claimedOffers: claimedOffers }
                      dispatch({ type: "SETUSER", payload: user })

                      dispatch({ type: "SETVERIFICATIONCODE", payload: values.code })
                      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.LOGIN, category: "VerificationCode", action: "Upload_Site",  label: "Submit"})
                      AnalyticsService.sendEvent("Upload Site", "Submit", "VerificationCode", { event: GTM_EVENTS.LOGIN })
                      history.push(ROUTE_PATHS.OFFERS)
                  }
                  else {
                      history.push(ROUTE_PATHS.HOME)
                  }
              },
              (err) => {
                  status = err.message
              }
          )  
    } catch (error) {
      status = error.message
    }
    setSubmitting(false)
    setStatus(status)
    }

  return (
    <section>
      <div className="container">
        <div className="row">
          <LandingImage />
          <div className="col">
            <div className="layout-purple text-center" style={{paddingTop: "1rem", paddingBottom: "1rem", minWidth: "18rem"}}>
              <h2 className="titleWhite24Text">{language.verification.HEADER_TEXT}</h2>
              <p className="instructionalWhite14Text">{language.verification.INSTRUCTIONS}</p>
              <EnterVerificationCodeForm onSubmit={confirmCode} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

VerificationCode.propTypes = {
  history: PropTypes.object.isRequired
}
