import React, { useContext, useState, useEffect } from "react"
import MediaQuery from 'react-responsive'
import { Formik, Form, Field } from 'formik'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import PropTypes from "prop-types"
import { ReCaptcha } from 'react-recaptcha-v3'

import { AppContext, LanguageContext } from "../components/context"
import { verifyReCaptchaV3 } from "../utils/Helpers"
import { ROUTE_PATHS } from "../utils/routes"
import { SubmissionApi } from "../api"
import AnalyticsService from "../utils/AnalyticsService"
import {GTM_EVENTS} from "../utils/GtmEvents"

import { RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm, BigOrangeText, RadioButtonComponent, ChooserItemComponent, LoadingComponent } from "../components"
import { RadioButtonInput, SelectInput } from "../components/inputs"
import { OrangeButton, Button, ErrorMessage } from "../components/styled"

export const Choose = ({ history }) => {
  const { state, dispatch } = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  useEffect(() => {
    AnalyticsService.sendUniquePageview(ROUTE_PATHS.CHOOSE);
  })

  if (state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  const choices = state.offer.Choices
  const defaultChoice = choices[0]
  const [isModalVisible, setModalVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const toggleModal = () => {
    setModalVisible(!isModalVisible)
  }

  const handleSubmit = async (values, setSubmitting) => {
    toggleModal()
    setSubmitting(false)
  }

  const confirmSelection = async (values, setSubmitting, setStatus) => {
    setIsLoading(true)
    try {
      // Pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route
      const iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, 0)
      iSubmission.offerFields.push({ key: 'SelectedItem', value: values.selectedItem })

      if (state.offer.RequireFileUpload) {
        dispatch({type: "SETISUBMISSION", payload: iSubmission})
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.CHOOSE_SUBMITTIED, category: "Upload_Site", action: "Submit",  label: "Choose"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "Choose", {event: GTM_EVENTS.CHOOSE_SUBMITTIED})
        history.push(ROUTE_PATHS.UPLOAD)
      } else {
        const updatedISubmission = await SubmissionApi.finalizeSubmission(iSubmission, state.user, state.cultureId)
        await SubmissionApi.sendThankYouSms(iSubmission, state)
        dispatch({ type: "SETISUBMISSION", payload: updatedISubmission })
        dispatch({ type: "SETSUBMITTED", payload: true })
        AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.CHOOSE_SUBMITTIED, category: "Upload_Site", action: "Submit",  label: "Choose"})
        AnalyticsService.sendEvent("Upload Site", "Submit", "Choose", {event: GTM_EVENTS.CHOOSE_SUBMITTIED})
        history.push(ROUTE_PATHS.STATUS)
      }

    } catch (error) {
      toggleModal()
      setStatus(error.message)
      setSubmitting(false)
      setIsLoading(false)
    }
  }

  return (
    <section>
      <div className="container hero3" style={{ paddingTop: "40px" }}>
        <MediaQuery query="(min-width: 1450px)">
          <LeftMagnetSwarm />
          <RightMagnetSwarm />
        </MediaQuery>
        <MediaQuery query="(max-width: 1449px)">
          <MediaQuery query="(min-width: 992px)">
            <LeftNarrowMagnetSwarm />
            <RightNarrowMagnetSwarm />
          </MediaQuery>
        </MediaQuery>
        <div className="row justify-content-center">
          <div className="col-8" style={{ textAlign: "center" }}>
            <BigOrangeText>{state.offer.ChooserHeader}</BigOrangeText>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col">
            <Formik initialValues={{ selectedItem: defaultChoice.Description }} onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}>
              {({ isSubmitting, values, setSubmitting, setStatus, status }) => (
                <Form style={{ marginTop: "20px" }}>
                  {choices.length <= 2 ?
                    <RadioButtonComponent>
                      {choices.map((choice) =>
                        <Field
                          component={RadioButtonInput}
                          key={choice.Description} name="selectedItem"
                          id={choice.Description} label={choice.Description}
                          checked={values.selectedItem === choice.Description} />)}
                    </RadioButtonComponent>
                    :
                    <Field style={{ "margin": "auto", "maxWidth": "400px" }}
                      component={SelectInput}
                      name="selectedItem">
                      {choices.map((choice) => {
                        return (<option key={choice.Description} value={choice.Description}>{choice.Description}</option>)
                      })}
                    </Field>
                  }

                  <ChooserItemComponent src={choices.find((i) => i.Description === values.selectedItem).ImageUrl} label={values.selectedItem} labelColor="#ffffff" />

                  {isLoading ?
                    <LoadingComponent className="mb-3" isChatSpinner={false} /> :
                    (
                      <OrangeButton type="submit" className="btn" disabled={isSubmitting} style={{
                        display: 'block',
                        margin: '20px auto'
                      }}>
                        {state.offer.RequireFileUpload ? language.form.button.NEXT : language.form.button.SUBMIT}
                      </OrangeButton>
                    )}
                  {status && <ErrorMessage className="text-center">{status}</ErrorMessage>}

                  <Modal isOpen={isModalVisible} centered style={{ maxWidth: "400px" }}>
                    <ModalBody style={{ backgroundColor: "#9469ab", border: "none" }}>
                      <div style={{ color: "#fa9d28", fontSize: "24px", fontFamily: "TTNorms-Bold", textAlign: "center", paddingLeft: "16px", paddingRight: "16px" }}>
                        {language.choose.HEADER_TEXT_MODAL + " " + values.selectedItem}?
                      </div>
                      <ChooserItemComponent src={choices.find((i) => i.Description === values.selectedItem).ImageUrl} />
                    </ModalBody>
                    <ModalFooter style={{ background: "transparent", backgroundColor: "#9469ab", border: "none" }}>
                      <OrangeButton className="btn" onClick={() => confirmSelection(values, setSubmitting, setStatus)}>{language.choose.YES}</OrangeButton>
                      <Button className="btn" style={{ color: "#ffffff", backgroundColor: "#a9a9a9" }} onClick={toggleModal}>{language.choose.NO}</Button>
                    </ModalFooter>
                  </Modal>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ReCaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3} action='Choose' verifyCallback={verifyReCaptchaV3} />
    </section>
  )
}

Choose.requiresAuth = true

Choose.propTypes = {
  history: PropTypes.object.isRequired
}
