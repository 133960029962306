import React, { useContext } from "react"
import PropTypes from "prop-types"

import { OrangeButton } from "../components/styled"
import { InstructionsComponent, ChooserItemComponent } from "../components"
import { AppContext, LanguageContext } from "../components/context"
import { createMarkup } from "../utils/Helpers"

import "../css/Layout.css"
import styled from "styled-components"

const CodeText = styled.div`
display: inline-block;
padding: 0 10px;
background-color: white;
color: black;
font-weight: bold;
font-size: 40px;
margin: 10px 0;
font-family: TTNorms-Bold;
`

export const RewardCodeFulfillmentComponent = ({hidePromoCode,onOpenLink, selectedItem, rewardCodes, thankYouLineOneText, thankYouLineTwoText}) => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)

  let chooserImageUrl
  let chooserText
  let chooserLink
  const choice = state.offer.Choices.find((f) => f.Description.toLowerCase() === selectedItem.toLowerCase())
  if (choice) {
    chooserImageUrl = choice.ImageUrl
    chooserText = choice.Description
    chooserLink = choice.ExternalUrl
    chooserLink = chooserLink.replace(/{rewardCode}/gi, rewardCodes[0])
  }

  const externalLinkText = state.offer.ExternalUrlText

  const renderCodes = () => {
    return rewardCodes.map((item, key) => {
      return <div key={key}><CodeText>{item}</CodeText></div>
    })
  }

  return (
    <section>
      <div className="row justify-content-center">
        <div className="col-7" style={{textAlign: "center"}}>
          {chooserImageUrl &&
            <ChooserItemComponent src={chooserImageUrl} label={chooserText} labelColor="#ffffff" />}
          {hidePromoCode &&
            <>
              <div className="titleOrange24Text" style={{marginTop: "20px"}}>{language.status.PROMO_CODE}:</div>
              {renderCodes()}
            </>
          }
          <div className="instructionalWhite14Text" style={{marginTop: "20px"}}>
            <p dangerouslySetInnerHTML={createMarkup(thankYouLineOneText)}></p>
          </div>
          {chooserLink &&
            <OrangeButton className="btn" style={{marginTop: "20px", color: "#ffffff"}} onClick={() => onOpenLink(chooserLink)}>{externalLinkText}</OrangeButton>}
        </div>
        <div className="col-7">
          <InstructionsComponent instructionsText={thankYouLineTwoText} />
        </div>
      </div>
    </section>
  )
}

RewardCodeFulfillmentComponent.propTypes = {
  hidePromoCode: PropTypes.string,
  onOpenLink: PropTypes.func.isRequired,
  link: PropTypes.object,
  selectedItem: PropTypes.string.isRequired,
  rewardCodes: PropTypes.array.isRequired,
  thankYouLineOneText: PropTypes.string.isRequired,
  thankYouLineTwoText: PropTypes.string
}
