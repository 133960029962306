import React, {useContext} from "react"
import PropTypes from "prop-types"
import {LanguageContext} from "../components/context"

import {OffersRowComponent} from "."

export const OffersRedeemedComponent = ({offers, noOffersText, onOfferClick}) => {
  const {language} = useContext(LanguageContext)
  return (
    <div className="container hero3">
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table myOffersTable">
              <tbody>
                <tr className="offersRedeemedHeader">
                  <th></th>
                  <th style={{textAlign: "center"}} scope="row">{language.offers.REDEEMED_OFFERS}</th>
                  <th>Status:</th>
                  <th></th>
                </tr>
                {offers.length === 0 && <tr className="offersRedeemedRow text-center"><td /><td>{noOffersText}<td></td></td><td></td><td /></tr>}
                {offers.length > 0 &&
                  offers.map((offer) =>
                    <OffersRowComponent                    
                      rowClassName="offersRedeemedRow"
                      linkClassName="offersRedeemedLinkCol"
                      offer={offer}
                      key={offer.OfferId}
                      onOfferClick={onOfferClick}
                      linkText={language.offers.LINK_AVAILABLE} />)}                      
                <tr className="offersRedeemedFooter">
                  <td></td><td></td><td></td><td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

OffersRedeemedComponent.propTypes = {
  offers: PropTypes.array,
  noOffersText: PropTypes.string.isRequired,
  onOfferClick: PropTypes.func.isRequired
}
