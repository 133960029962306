const axios = require("axios")

const DEFAULT_CONFIG = {
  baseURL: process.env.REACT_APP_SHARED_API_URL
}

const AUTH_TOKEN_STORAGE_KEY = "METRO_PROMO_TOKEN_KEY"

export default class BaseApi {
  constructor(config = DEFAULT_CONFIG, storage = sessionStorage) {
    this.config = config
    this.storage = storage
    this.httpClient = axios.create({
      baseURL: this.config.baseURL,
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })

    this.httpClient.interceptors.response.use((response) => {
      return response
    }, (error) => {
      // handle log in error
      if (error.config.url.replace(error.config.baseUrl, "") === "auth-token") {
        return Promise.reject(error)
      }

      if (error.response && error.response.status === 401) {
        this.deleteAuthToken()
        window.location.reload()
      }

      return Promise.reject(error)
    })
  }

  getAuthToken() {
    return this.storage.getItem(AUTH_TOKEN_STORAGE_KEY)
  }

  setAuthToken(token) {
    this.storage.setItem(AUTH_TOKEN_STORAGE_KEY, token)
  }

  getUserInfo() {
    try {
      let base64Url = this.getAuthToken().split('.')[1]
      let base64 = base64Url.replace('-', '+').replace('_', '/')
      return JSON.parse(window.atob(base64))
    } catch (e) {
      return null
    }
  }

  deleteAuthToken() {
    this.storage.removeItem(AUTH_TOKEN_STORAGE_KEY)
  }

  getHeaders() {
    const token = this.getAuthToken()    
    if (token) {
      const Authorization = `Bearer ${token}`
      return {Authorization, ...this.config.headers}
    } else {
      return this.config.headers
    }
  }

  callWithHeaders(options) {
    return this.httpClient({
      ...options,
      headers: {
        ...this.getHeaders()
      }
    })
  }

  encodeQueryString(params) {
    return "?" + Object.keys(params).map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    }).join("&")
  }

  async get(url, data, requestConfig = {}) {
    let urlWithParams = url

    if (data) {
      urlWithParams = `${url}${this.encodeQueryString(data)}`
    }

    const response = await this.callWithHeaders({ method: "GET", url: urlWithParams, ...requestConfig })
    return response
  }

  async post(url, data) {
    const response = await this.callWithHeaders({ method: "POST", url, data })
    return response
  }
  async put(url, data) {
    const response = await this.callWithHeaders({ method: "PUT", url, data })
    return response
  }
  async patch(url, data) {
    const response = await this.callWithHeaders({ method: "PATCH", url, data })
    return response
  }
  async delete(url, data) {
    const response = await this.callWithHeaders({ method: "DELETE", url, data })
    return response
  }
}
