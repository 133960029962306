import React, {useContext, useEffect} from "react"
import MediaQuery from 'react-responsive'
import PropTypes from "prop-types"

import {AppContext} from "../components/context/AppContext"
import {RegistrationForm} from "../components/forms"
import {FoundPromotionComponent, RightMagnetSwarm, LeftMagnetSwarm, RightNarrowMagnetSwarm, LeftNarrowMagnetSwarm} from "../components"
import AnalyticsService from "../utils/AnalyticsService"
import {GTM_EVENTS} from "../utils/GtmEvents"
import { SubmissionApi, VirtualCardApi} from "../api"
import {ROUTE_PATHS} from "../utils/routes"

export const Register = ({history}) => {
    const { state, dispatch } = useContext(AppContext)
    const fulfillmentType = state.offer.FulfillmentType.toUpperCase()
    useEffect(() => {
      AnalyticsService.sendUniquePageview(ROUTE_PATHS.REGISTER);
    })

  if (state.isSubmitted) {
    history.replace(ROUTE_PATHS.HOME)
  }

  const submitRegistrationForm = async (values, {setSubmitting, setStatus}) => {
    let user = {
      phoneNumber: state.user.phoneNumber,
      firstName: state.user.firstName,
      lastName: state.user.lastName,
      address1: values.address1.trim(),
      address2: values.address2.trim(),
      city: values.city.trim(),
      state: values.state,
      postalCode: values.postalCode,
      email: values.email,
      availableOffers: state.user.availableOffers,
      claimedOffers: state.user.claimedOffers
    }

    // Pass in 0 for SubmitId value as this is only needed for Metro Exceptions offer which will never be on this route
    const iSubmission = await SubmissionApi.getISubmission(state.offer.OfferId, 0)

    try {
        dispatch({type: "SETUSER", payload: user})
        await pushToNextPage(iSubmission, user)
    } catch (error) {
      setStatus(error.message)
    }

    setSubmitting(false)
  }

  const pushToNextPage = async (iSubmission, user) => {
    if (state.offer.RequireChooser) {
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.REGISTRATION_SUBMITTED, category: "Register", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Register", {event: GTM_EVENTS.REGISTRATION_SUBMITTED})
      history.push(ROUTE_PATHS.CHOOSE)
    } else if (state.offer.RequireFileUpload && state.offer.IsHint) {
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.REGISTRATION_SUBMITTED, category: "Register", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Register", {event: GTM_EVENTS.REGISTRATION_SUBMITTED})
      history.push(ROUTE_PATHS.UPLOAD_STEP)
    } else if (state.offer.RequireFileUpload) {
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.REGISTRATION_SUBMITTED, category: "Register", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Register", {event: GTM_EVENTS.REGISTRATION_SUBMITTED})
      history.push(ROUTE_PATHS.UPLOAD)
    } else {
        const updatedISubmission = await SubmissionApi.finalizeSubmission(iSubmission, user, state.cultureId)
        if (fulfillmentType === "CITIVIRTUALCARD") {
            await VirtualCardApi.activateVirtualCard(iSubmission.SubmitId, state.cultureId)
        }
        else
          await SubmissionApi.sendThankYouSms(iSubmission, state)
      dispatch({type: "SETISUBMISSION", payload: updatedISubmission})
      dispatch({ type: "SETSUBMITTED", payload: true })
      AnalyticsService.sendGa4Event({event: GTM_EVENTS.EVENT , trigger: GTM_EVENTS.SUBMISSION_CREATED, category: "Register", action: "Upload_Site",  label: "Submit"})
      AnalyticsService.sendEvent("Upload Site", "Submit", "Register", {event: GTM_EVENTS.SUBMISSION_CREATED})
      history.push(ROUTE_PATHS.STATUS)
    }
  }

  return (
    <section>
      <div className="container hero3">
        <MediaQuery query="(min-width: 1450px)">
          <LeftMagnetSwarm />
          <RightMagnetSwarm />
        </MediaQuery>
        <MediaQuery query="(max-width: 1449px)">
          <MediaQuery query="(min-width: 992px)">
            <LeftNarrowMagnetSwarm />
            <RightNarrowMagnetSwarm />
          </MediaQuery>
        </MediaQuery>
        <div className="row">
          <div className="col">
            <FoundPromotionComponent offerLineOne={state.offer.HeaderLineOne} offerLineTwo={state.offer.HeaderLineTwo} offerHeaderImageUrl={state.offer.HeaderImageUrl} offerHeaderImageType={state.offer.HeaderImageType} offerHeaderImageBytes={state.offer.HeaderImageBytes} />
          </div>
        </div>
        <div className="row justify-content-center">
          <MediaQuery query="(min-width: 992px)">
            <div className="col-6">
              <RegistrationForm onSubmit={submitRegistrationForm} />
            </div>
          </MediaQuery>
          <MediaQuery query="(max-width: 991px)">
            <div className="col">
              <RegistrationForm onSubmit={submitRegistrationForm} />
            </div>
          </MediaQuery>
        </div>
      </div>
    </section>
  )
}

Register.propTypes = {
  history: PropTypes.object
}

Register.requiresAuth = true
