import React from "react";
import "../css/Faq.css";

function FaqUpload({ title, content }) {
    return (
        <>
            <div className="upTitle">
                <span className="itemTitle">{title}</span>
            </div>
            <div className="upContent">
                <p style={{textAlign: "left"}}>{content}</p>
            </div>
        </>
    )
}

export default FaqUpload;