import React, {useReducer, createContext, useEffect} from "react"
import PropTypes from "prop-types"
import * as Sentry from '@sentry/browser'

export const STATE_NAME = "METRO_PROMO_STATE"

const initialState = {
  controlOffer: {},
  user: {phoneNumber: "", firstName: "", lastName: "", availableOffers: [], claimedOffers: []},
  offer: {},
  iSubmission: {},
  files: [],
  isReCaptchaV3Valid: true,
  reCaptchaV3LoginToken: "",
  isSubmitted: false,
  verificationCode: "",
  metaVirtualCard: {},
  faq: [],
  cultureId: 1,
  hintAddress: {}
}

const persistedState = JSON.parse(sessionStorage.getItem(STATE_NAME))

const finalInitialState = {...initialState, ...persistedState}

const appReducer = (state, action) => {
  switch (action.type) {
  case "SETCONTROLOFFER":
    return {...state, controlOffer: action.payload}
  case "SETISUBMISSION":
    return {...state, iSubmission: action.payload }
  case "SETFILES":
    return {...state, files: action.payload}
  case "SETUSER":
    Sentry.configureScope(function(scope) {
      scope.setUser({"id": action.payload.phoneNumber})
    })
    return {...state, user: action.payload}
  case "SETOFFER":
    return {...state, offer: action.payload}
  case "SETISRECAPTCHAV3VALID":
    return {...state, isReCaptchaV3Valid: action.payload }
  case "SETRECAPTCHAV3LOGINTOKEN":
    return {...state, reCaptchaV3LoginToken: action.payload }
  case "SETSUBMITTED":
    return {...state, isSubmitted: action.payload}
  case "SETVERIFICATIONCODE":
    return {...state, verificationCode: action.payload}
  case "SETMETAVIRTUALCARD":
    return {...state, metaVirtualCard: action.payload}
  case "RESETSTATE":
    return {...state, ...initialState, user: action.payload}
  case "SETCULTUREID":
    return {...state, cultureId: action.payload}
  case "SETHINTADDRESS":
    return {...state, hintAddress: action.payload}  
  default:
    return state
  }
}

export const AppContext = createContext()

export const AppProvider = props => {
  const [state, dispatch] = useReducer(appReducer, finalInitialState)

  useEffect(() => {
    sessionStorage.setItem(STATE_NAME, JSON.stringify(state))
  }, [state])

  return (
    <AppContext.Provider value={{state, dispatch}}>
      {props.children}
    </AppContext.Provider>
  )
}

AppProvider.propTypes = {
  children: PropTypes.element
}
