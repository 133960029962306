const CHOOSE = {
  en: {
    HEADER_TEXT_MODAL: "Are you sure you want the",
    YES: "Yes",
    NO: "No, I changed my mind"
  },
  sp: {
    HEADER_TEXT_MODAL: "¿Está seguro de que quiere ",
    YES: "Sí",
    NO: "No, cambié de opinión"
  }
}

const ELIGIBILITY = {
  en: {
    MESSAGE_ONE: "Sorry, it looks like you are not eligible for any offers at this time",
    MESSAGE_TWO: "We'll send you a text message if we find an offer you're eligible for!"
  },
  sp: {
    MESSAGE_ONE: "Lo siento, parece que no es elegible para ninguna oferta en este momento",
    MESSAGE_TWO: "¡Le enviaremos un mensaje de texto si encontramos una oferta para la que sea elegible!"
  }

}

const ERRORS = {
  en: {
    ELIGIBILITY: "Unable to determine eligibility for phone number {phoneNumber} and claim code {code}. Try again later",
    GENERAL: "Uh oh! Something went wrong. Please try again",
    CARD_INFO: "Unable to get card info",
    TRANSACTIONS: "Unable to get card Transactions",
    ACTIVATION: "There was a problem processing your activation. Please try again",
    OFFER_PHONE: "Unable to get offer from phone number",
    PROCESS_REQUEST: "There was an issue processing your request",
    TERMS: "Acceptance of Terms & Conditions is required",
    VERIFICATION: "Unable to send verification code. Try again later.",
    VERIFICATION_PHONE: "Unable to send verification code to {phoneNumber}. Try again later.",
    VERIFICATION_VALIDATION: "Unable to validate verification code {verificationCode} for phone number {phoneNumber}. Try again later.",
    ADDRESS: "Unable to validate address. Try again later.",
    STORE_ADDRESS: "The address you have provided is not allowed. Please re-enter your residential address or P.O. Box.",
    upload: {
      MIN_UPLOADS: "You must upload at least one file",
      MAX_UPLOADS: "The maximum number of uploads allowed is 15.",
      MAX_FILE_SIZE: "Please ensure each file is below 5MB and are of the type .pdf, .png, or .jpeg.",
      DUPLICATES: "Files with duplicate names as those ready for upload were skipped."
    }
  },
  sp: {
    ELIGIBILITY: "No se puede determinar la elegibilidad del número de teléfono {phoneNumber} y el código de reclamación {code}. Inténtelo más tarde",
    GENERAL: "¡Qué pena! Algo salió mal. Inténtelo de nuevo",
    CARD_INFO: "No se puede obtener la información de la tarjeta",
    TRANSACTIONS: "No se pueden obtener las transacciones de la tarjeta",
    ACTIVATION: "Hubo un problema al procesar su activación. Inténtelo de nuevo",
    OFFER_PHONE: "No se puede obtener una oferta del número de teléfono",
    PROCESS_REQUEST: "Hubo un problema al procesar su solicitud",
    TERMS: "Se requiere la aceptación de los Términos y condiciones",
    VERIFICATION: "No se puede enviar el código de verificación. Inténtelo más tarde.",
    VERIFICATION_PHONE: "No se puede enviar el código de verificació a {phoneNumber}. Inténtelo más tarde.",
    VERIFICATION_VALIDATION: "No se puede validar el código de verificación {verificationCode} para el número de teléfono {phoneNumber}. Inténtelo más tarde.",
    ADDRESS: "No se puede validar la dirección. Inténtelo más tarde.",
    STORE_ADDRESS: "The address you have provided is not allowed. Please re-enter your residential address or P.O. Box.",
    upload: {
      MIN_UPLOADS: "Debe cargar al menos un archivo.",
      MAX_UPLOADS: "El número máximo de cargas permitidas es de 15.",
      MAX_FILE_SIZE: "Asegúrese de que cada archivo sea de menos de 5 MB y del tipo .pdf, .png, o .jpeg.",
      DUPLICATES: "Los archivos con nombres duplicados como los que están listos para ser cargados fueron omitidos."
    }
  }
}

const FAQ = {
  en: {
    HEADER_TEXT: "Virtual Prepaid Mastercard Promotion Frequently Asked Questions",
    ERROR_MESSAGE: "Unable to load Faqs at this time. Please try again later"
  },
  sp: {
    HEADER_TEXT: "Preguntas frecuentes",
    ERROR_MESSAGE: "No se pueden cargar las preguntas frecuentes en este momento. Inténtelo de nuevo más tarde"
  }
}

const FOOTER = {
  en: {
    PRIVACY_POLICY: "Privacy Policy",
    PRIVACY_HUB: "Privacy Hub",
    TERMS: "Terms & Conditions",
    FAQ: "Frequently Asked Questions",
    DO_NOT_SELL: "Do Not Sell My Personal Information",
    COPYRIGHT: "T-Mobile USA, Inc. All rights reserved"
  },
  sp: {
    PRIVACY_POLICY: "Política de privacidad",
    PRIVACY_HUB: "Centro de Privacidad",
    TERMS: "Términos y condiciones",
    FAQ: "Preguntas frecuentes",
    DO_NOT_SELL: "No vender mi información personal",
    COPYRIGHT: "T-Mobile USA, Inc. Todos los derechos reservados"
  }
}

const FORMS = {
  en: {
    REQUIRED_FIELDS_INDICATOR: "* Indicates required fields",

    button: {
      SEND_CODE: "Send Code",
      NEXT: "Next",
      REQUEST: "Request Card",
      CONFIRM_CODE: "Confirm Code",
      SUBMIT: "Submit",
      UPLOADING: "Uploading",
      CHOOSE_FILE: "Choose A File"
    },
    placeholder: {
      ADDRESS: "Street Address / Calle",
      ADDRESS2: "Apt./Suite # / Depto/Suite #",
      CITY: "City / Ciudad",
      ZIP: "Postal Code / Código Postal",
      STATE: "State / Estado",
      VERIFICATION_CODE: "Verification Code*",
      FIRST_NAME: "First Name / Nombre*",
      LAST_NAME: "Last Name / Apellido*",
      NAME: "Name",
      PHONE: "Mobile Phone # / # de Teléfono Celular*",
      EMAIL: "Email / Email"
    },
    required: {
      ADDRESS: "Address is required",
      ADDRESS2: "Apt./Suite # is required",
      CITY: "City is required",
      ZIP: "Zip Code is required",
      STATE: "State is required",
      FIRST_NAME: "First Name is required",
      LAST_NAME: "Last Name is required",
      RECAPTCHA: "reCAPTCHA is required",
      VERIFICATION: "Verification Code is Required",
      EMAIL: "Email is required",
      PHONE: "Mobile Phone # is required",
    },
    validation: {
      ADDRESS: "Address must start with a street number or PO Box",
      ALPHA_NUMERIC: "Please enter alpha numeric characters",
      CITY: "City is Invalid",
      ZIP: "Zip Code is invalid",
      ZIP_LIMIT: "Zip Code must be five digits",
      EMAIL: "Email is invalid",
      PHONE: "Invalid Mobile Phone #",
      VERIFICATION: "Invalid Verification Code or Code Expired",
      NAME: "Please enter letters from the standard alphabet",
    }
  },
  sp: {
    REQUIRED_FIELDS_INDICATOR: "* Indica los campos requeridos",

    button: {
      SEND_CODE: "Enviar código",
      NEXT: "Siguiente",
      REQUEST: "Obtener Tarjeta",
      CONFIRM_CODE: "Confirmar el código",
      SUBMIT: "Enviar",
      UPLOADING: "Cargando",
      CHOOSE_FILE: "Escoge un Archivo"
    },
    placeholder: {
      ADDRESS: "La dirección",
      ADDRESS2: "Número de apartamento/oficina",
      CITY: "La Ciudad",
      ZIP: "Código postal",
      STATE: "Estatdo",
      VERIFICATION_CODE: "Código de verificación*",
      FIRST_NAME: "Nombre*",
      LAST_NAME: "Apellido*",
      NAME: "Nombre",
      PHONE: "Número de teléfono móvil*",
      EMAIL: "Correo electrónico"
    },
    required: {
      ADDRESS: "Se requiere la dirección",
      ADDRESS2: "Se requiere el número de apartamento/oficina",
      CITY: "Se requiere la ciudad",
      ZIP: "Se requiere el código postal",
      STATE: "Se requiere el estado",
      FIRST_NAME: "Se requiere nombre",
      LAST_NAME: "Se requiere apellido",
      RECAPTCHA: "Se requiere reCAPTCHA",
      VERIFICATION: "Se requiere el código de verificación",
      EMAIL: "Se requiere el correo electrónico",
      PHONE: "Se requiere el número de teléfono móvil",
    },
    validation: {
      ADDRESS: "La dirección debe comenzar con un número de calle o un apartado postal",
      ALPHA_NUMERIC: "Por favor ingrese caracteres alfanuméricos",
      CITY: "La ciudad no es válida",
      ZIP: "El código postal no es válido",
      ZIP_LIMIT: "El código postal debe ser de cinco dígitos",
      EMAIL: "El correo electrónico no es válido",
      PHONE: "Número de teléfono móvil inválido",
      VERIFICATION: "Código de verificación inválido o código caducado",
      NAME: "Por favor ingrese letras del alfabeto estándar",
    }
  }
}

const GENERAL = {
  en: {
    LOADING: "Loading ..."
  },
  sp: {
    LOADING: "Cargando ..."
  }
}

const HOME = {
  en: {
    HEADER_TEXT: "Let's Get Started",
    INSTRUCTIONS: "We’ll send a text message with a verification code to the mobile phone number you enter <br><br>  Te enviaremos un mensaje de texto con codigo de verificación al número de teléfono que registraste",
    ERROR_PHONE: "Mobile Phone # must match number that received offer SMS",
    FAQ_VISIT: "Questions before you continue? Click ",
    FAQ_VISIT_HERE: "here"
  },
  sp: {
    HEADER_TEXT: "Empecemos",
    INSTRUCTIONS: "Enviaremos un mensaje de texto con un código de verificación al número de teléfono móvil que ingrese",
    ERROR_PHONE: "El número de teléfono móvil debe coincidir con el número que recibió la oferta por SMS",
    FAQ_VISIT: "Tienes preguntas antes de continuar? Click ",
    FAQ_VISIT_HERE: "aquí"
  }
}

const VIRTUAL_CARD = {
  en: {
    INSTRUCTION: "Please activate your card to start using your reward today!",
    ACTIVATE_CARD: "Activate Card",
    REQUEST_CARD:"You will receive an SMS with your virtual claim code link within 24 hours.",
    CURRENT_BALANCE: "Current Balance",
    DETAILS: "Additional Cardholder Details",
    ADDRESS: "Street Address",
    CITY_STATE_ZIP: "City, State Zip",
    PHONE: "Phone",
    HISTORY: "Transaction History",
    DATE: "Date",
    DESCRIPTION: "Description",
    TYPE: "Type",
    AMOUNT: "Amount",
    VIEW_CARD: "View Card",
    CARD_USAGE: "Your virtual card can be used to shop online anywhere Visa is accepted",
    SHOP: "Shop Now!",
    CARD_INFO: "Card Information",
    CARD_AGREEMENT: "Cardholder Agreement"
  },
  sp: {
    INSTRUCTION: "Active su tarjeta ¡para empezar a utilizar su recompensa hoy mismo!",
    ACTIVATE_CARD: "Activar tarjeta",
    REQUEST_CARD:"Usted recibira un SMS para obtener su codigo con el URL dentro de 24 hours.",
    CURRENT_BALANCE: "Saldo actual",
    DETAILS: "Datos del titular de la tarjeta adicional",
    ADDRESS: "Dirección",
    CITY_STATE_ZIP: "Ciudad, código postal del estado",
    PHONE: "Teléfono",
    HISTORY: "Historial de transacciones",
    DATE: "Fecha",
    DESCRIPTION: "Descripción",
    TYPE: "Tipo",
    AMOUNT: "Monto",
    VIEW_CARD: "Ver tarjeta",
    CARD_USAGE: "Su tarjeta virtual puede utilizarse para comprar en línea en cualquier lugar que se acepte Visa",
    SHOP: "¡Comprar ahora!",
    CARD_INFO: "Información de la tarjeta",
    CARD_AGREEMENT: "Acuerdo del titular de la tarjeta"
  }
}

const OFFER_OVER = {
  en: {
    ORANGE_TEXT: "This promotion",
    WHITE_TEXT: "has ended"
  },
  sp: {
    ORANGE_TEXT: "Esta promoción",
    WHITE_TEXT: "ha finalizado"
  }
}

const OFFERS = {
  en: {
    HEADER_TEXT: "My Metro Offers",
    SELECT_OFFER: "Select an available offer below",
    EXPIRATION: "Expiration Date",
    OFFER_NAME: "Offer Name",
    NO_ACTIVE_OFFERS: "There are no active offers to redeem",
    REDEEMED_OFFERS: "My Redeemed Offers",
    NO_REDEEMED_OFFERS: "You have not redeemed any offers yet",
    LINK_AVAILABLE: "Claim Offer / Actíva la oferta",
    LINK_REDEEMED: "View Details / Ve detalles",
    RESUBMIT: "ReSubmit/Reenviar"
  },
  sp: {
    HEADER_TEXT: "Mis ofertas de Metro",
    SELECT_OFFER: "Seleccione una oferta disponible a continuación",
    EXPIRATION: "Fecha de vencimiento",
    OFFER_NAME: "Nombre de la oferta",
    NO_ACTIVE_OFFERS: "No hay ofertas activas para canjear",
    REDEEMED_OFFERS: "Mis ofertas canjeadas",
    NO_REDEEMED_OFFERS: "Aún no ha canjeado ninguna oferta",
    LINK_AVAILABLE: "Reclamar oferta",
    LINK_REDEEMED: "Ver detalles"
  }
}

const REGISTRATION = {
  en: {
    HEADER_TEXT: "Enter Your Information",
    NAME: "Name",
    PHONE: "Mobile Phone #",
    ERROR_ADDRESS: "Sorry, it looks like this address was already used to redeem this offer"
  },
  sp: {
    HEADER_TEXT: "Ingrese su información",
    NAME: "Nombre",
    PHONE: "Número de teléfono móvil",
    ERROR_ADDRESS: "Lo siento, parece que esta dirección ya fue usada para canjear esta oferta"
  }
}

const STATUS = {
  en: {
    ERROR_VIRTUAL_CARD: "There was a problem retrieving your virtual card. Please try again",
    PROMO_CODE: "Your Promo Code",
    footer: {
      QUESTIONS: "Questions?",
      LINE_ONE: "For questions on the status of your submission for this promotion,",
      LINE_TWO: "please call 800-999-6389",
      LINE_THREE: "Monday - Friday 7 AM - 7 PM CT",
      TRACKING: "Your Submission Tracking ID:"
    }
  },
  sp: {
    ERROR_VIRTUAL_CARD: "Hubo un problema al recuperar su tarjeta virtual. Inténtelo de nuevo",
    PROMO_CODE: "Su código de promoción",
    footer: {
      QUESTIONS: "¿Tiene alguna pregunta?",
      LINE_ONE: "Para preguntas sobre el estado de su presentación para esta promoción,",
      LINE_TWO: "llame al 800-999-6389",
      LINE_THREE: "de lunes a viernes, de 7 a. m. a 7 p. m., CT",
      TRACKING: "Su identificación de seguimiento de envíos:"
    }
  }
}

const TERMS = {
  en: {
    HEADER_TEXT: "Please read and agree to the Terms and Conditions",
    ACCEPT_TERMS: "I accept the Terms & Conditions*"
  },
  sp: {
    HEADER_TEXT: "Lea y acepte los Términos y condiciones",
    ACCEPT_TERMS: "Acepto los Términos y condiciones*"
  }
}

const UPLOAD = {
  en: {
    HEADER_TEXT: "Upload Page",
    DRAG_AND_DROP: "Drag and Drop some files here to upload",
    ACCEPTABLE_FORMATS: "Acceptable file formats: ",
    FILE_FORMATS: "JPEG, PNG, or PDF",
    MAX_SIZE: "Maximum upload file size: ",
    MY_UPLOADS: "My Uploads:"
  },
  sp: {
    HEADER_TEXT: "Página para cargas",
    DRAG_AND_DROP: "Arrastre y suelte algunos archivos aquí para cargar.",
    ACCEPTABLE_FORMATS: "Formatos de archivo aceptados: ",
    FILE_FORMATS: "JPEG, PNG, o PDF",
    MAX_SIZE: "Tamaño máximo del archivo que se cargará: ",
    MY_UPLOADS: "Mis cargas:"
  }
}

const UPLOAD_STEP = {
  en: {
    FIRST_SECTION_TITLE: "How to submit your payoff information",
    SECOND_SECTION_TITLE: "Upload an image of your final bill",
    THIRD_SECTION_TITLE: "Submit for processing",
    HEADER_TEXT: "Next: 3 Steps to submit for pay off",
    DRAG_AND_DROP: "Drag and Drop some files here to upload",
    ACCEPTABLE_FORMATS: "Acceptable file formats: ",
    FILE_FORMATS: "JPEG, PNG, or PDF",
    MAX_SIZE: "Maximum upload file size: ",
    MY_UPLOADS: "My Uploads:"
  },
  sp: {
    FIRST_SECTION_TITLE: "Como enviar su información de pago",
    SECOND_SECTION_TITLE: "Adjunte una imagen de su factura final",
    THIRD_SECTION_TITLE: "Enviar para procesar",
    HEADER_TEXT: "Siguiente: 3 Pasos para enviar para pagar",
    DRAG_AND_DROP: "Arrastre y suelte algunos archivos aquí para cargar.",
    ACCEPTABLE_FORMATS: "Formatos de archivo aceptados: ",
    FILE_FORMATS: "JPEG, PNG, o PDF",
    MAX_SIZE: "Tamaño máximo del archivo que se cargará: ",
    MY_UPLOADS: "Mis cargas:"
  }
}

const VERIFICATION = {
  en: {
    HEADER_TEXT: "Enter Verification Code",
    INSTRUCTIONS: "You should've received a text message with a verification code. Enter it below"
  },
  sp: {
    HEADER_TEXT: "Ingrese el código de verificación",
    INSTRUCTIONS: "Debería haber recibido un mensaje de texto con un código de verificación. Ingréselo abajo"
  }
}

const MAINTENANCE = {
  en: {
    HEADER_TEXT: "Temporarily Unavailable",
    MESSAGE: "The site is temporarily undergoing maintenance, we'll be back soon.",
    THANKS: "Thank you for your patience."
  },
  sp: {
    HEADER_TEXT: "Temporalmente no disponible",
    MESSAGE: "Este sitio está temporalmente bajo mantenimiento, regresaremos pronto.",
    THANKS: "Gracias por tu paciencia."
  }
}

//keep export at bottom of file
export const Languages = {
  en: {
    home: HOME.en,
    faq: FAQ.en,
    eligibility: ELIGIBILITY.en,
    form: FORMS.en,
    verification: VERIFICATION.en,
    offerOver: OFFER_OVER.en,
    footer: FOOTER.en,
    offers: OFFERS.en,
    status: STATUS.en,
    virtualCard: VIRTUAL_CARD.en,
    terms: TERMS.en,
    choose: CHOOSE.en,
    errors: ERRORS.en,
    registration: REGISTRATION.en,
    general: GENERAL.en,
    upload: UPLOAD.en,
    upload_step: UPLOAD_STEP.en,
    maintenance: MAINTENANCE.en
  },
  sp: {
    home: HOME.sp,
    faq: FAQ.sp,
    eligibility: ELIGIBILITY.sp,
    form: FORMS.sp,
    verification: VERIFICATION.sp,
    offerOver: OFFER_OVER.sp,
    footer: FOOTER.sp,
    offers: OFFERS.sp,
    status: STATUS.sp,
    virtualCard: VIRTUAL_CARD.sp,
    terms: TERMS.sp,
    choose: CHOOSE.sp,
    errors: ERRORS.sp,
    registration: REGISTRATION.sp,
    general: GENERAL.sp,
    upload: UPLOAD.sp,
    upload_step: UPLOAD_STEP.sp,
    maintenance: MAINTENANCE.sp
  }
}
