import React, {useContext} from "react"
import PropTypes from "prop-types"

import {LandingImage} from "./styled"
import {LoginForm} from "./forms"
import {LanguageContext} from "./context"
import {createMarkup} from "../utils/Helpers"
import '../css/Text.css'
import {Link} from "react-router-dom"
import {ROUTE_PATHS} from '../utils/routes'

export const StandardGetStartedComponent = ({submitLoginForm, claimCode}) => {
  const {language} = useContext(LanguageContext)
  return (
    <section>
      <div className="row">
        <LandingImage />
        <div className="col">
          <div className="layout-purple text-center" style={{paddingTop: "1rem", paddingBottom: "1rem", minWidth: "18rem"}}>
            <h2 className="titleWhite42Text">{language.home.HEADER_TEXT}</h2>
            {!claimCode && <p className="instructionalWhite14Text" dangerouslySetInnerHTML={createMarkup(language.home.INSTRUCTIONS)}></p>}
            <LoginForm onSubmit={submitLoginForm} claimCode={claimCode} />
            <br />
            <Link className="link-metro" to={ROUTE_PATHS.FAQ}>{language.home.FAQ_VISIT} <span className="link-metro-underline">{language.home.FAQ_VISIT_HERE}</span>.</Link>
          </div>
        </div>
      </div>
    </section>
  )
}

StandardGetStartedComponent.propTypes = {
  submitLoginForm: PropTypes.func.isRequired,
  claimCode: PropTypes.string
}
