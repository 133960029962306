import React, {useContext} from "react"
import {PropTypes} from "prop-types"
import {Formik, Form, Field} from "formik"
import * as Yup from 'yup'
import {ReCaptcha} from 'react-recaptcha-v3'
import {LanguageContext} from "../components/context"

import {verifyReCaptchaV3} from "../utils/Helpers"
import {CheckboxInput} from "./inputs"
import {OrangeButton, ErrorMessage} from "./styled"
import "../css/Text.css"
import "../css/Layout.css"

import {createMarkup} from "../utils/Helpers"



export const TermsAndConditionsComponent = ({terms, onSubmit}) => {
  const {language} = useContext(LanguageContext)

  const initialValues = {
    accept: false
  }

  const TermsSchema = Yup.object().shape({
    accept: Yup.boolean()
      .oneOf([true], `${language.errors.TERMS}`)
      .required(`${language.errors.TERMS}`)
  })

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={TermsSchema} onSubmit={onSubmit}>
        {({status, isSubmitting}) => (
          <Form>
            <div style={{textAlign: "center", paddingTop: "20px"}}>
              <p className="instructionalWhite14Text"><strong>{language.terms.HEADER_TEXT}</strong></p>
              <p className="requiredFieldsText">{language.form.REQUIRED_FIELDS_INDICATOR}</p>
              <div className="scrollbar" style={{padding: "1rem"}}>
                <p style={{margin: 0}} dangerouslySetInnerHTML={createMarkup(terms)}></p>
              </div>
              <Field
                name="accept"
                className="agree-to-terms"
                component={CheckboxInput}
                label={language.terms.ACCEPT_TERMS}
                style={{color: '#ffffff', fontSize: '14px'}} />
              <div className="mt-3" style={{paddingBottom: "1rem"}}>
                <OrangeButton type="submit" id="btnSubmit" className="btn" primary disabled={isSubmitting}>{language.form.button.NEXT}</OrangeButton>
                {status && <ErrorMessage className="text-center">{status}</ErrorMessage>}
              </div>
            </div>
            <ReCaptcha sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V3} action='AgreeTerms' verifyCallback={verifyReCaptchaV3} />
          </Form>
        )}
      </Formik>
    </>
  )
}

TermsAndConditionsComponent.propTypes = {
  terms: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
}
