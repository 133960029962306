import BaseApi from "./BaseApi"
import * as Sentry from '@sentry/browser'

const PROMOTION_ADMIN_ENDPOINT = "/promoadmin"
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID

class PromoAdminApi extends BaseApi {
  async getOffer(offerId, phoneNumber, claimCode, isEdgeOrIE, cultureId) {
    try {
      const response = await this.get(`${PROMOTION_ADMIN_ENDPOINT}/getmetropromooffer?offerId=${offerId}&phoneNumber=${phoneNumber}&claimCode=${claimCode}&cultureId=${cultureId}`)
      const offer = response.data
      if (isEdgeOrIE) {
        const offerHeaderImage = await this.getImage(offer.OfferId, offer.HeaderImageKeyword)
        const offerWithHeaderImage = {...offer, HeaderImageBytes: offerHeaderImage.data, HeaderImageType: offerHeaderImage.headers["content-type"]}
        return offerWithHeaderImage
      }
      return offer
    } catch (error) {
      Sentry.captureException(error)
      throw new Error(`Unable to get offer for offerId ${offerId} from promo admin`)
    }
  }

  async getImage(offerId, keyword) {
    try {
      const response = await this.get(`${PROMOTION_ADMIN_ENDPOINT}/image?offerId=${offerId}&keyword=${keyword}`)
      return response
    } catch (error) {
      Sentry.captureException(error)
      throw new Error(`Unable to get image from offerId ${offerId} and keyword ${keyword} from promo admin`)
    }
  }

  async getClientFaqs(cultureId) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/${CLIENT_ID}/faq?cultureId=${cultureId}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      Sentry.captureException(error)
      throw new Error(error)
    }
  }

  async getClientAsset(keyword) {
    try {
      const endpoint = `${PROMOTION_ADMIN_ENDPOINT}/${CLIENT_ID}/asset/${keyword}`
      const response = await this.get(endpoint)
      return response.data
    } catch (error) {
      throw new Error("Unable to retrieve client asset")
    }
  }
}

export default new PromoAdminApi()
