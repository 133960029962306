import PropTypes from "prop-types"
import {withRouter} from "react-router-dom"
import {getDateAsString} from "../utils/Helpers"
import { ROUTE_PATHS } from "../utils/routes"
import {LanguageContext} from "../components/context"
import React, {useContext} from "react"
import {AuthApi, SubmissionApi} from "../api"

export const OffersRowComponent = withRouter(({rowClassName, linkClassName, offer, onOfferClick, linkText, history}) => {
  const {language} = useContext(LanguageContext)

  async function ReSubmit(iSubmitID,claimCode) {
    const isEligible = await AuthApi.login(offer.PhoneNumber, claimCode, offer.OfferId)
    if (isEligible) {
      await SubmissionApi.DeleteSubmissionImages(iSubmitID,offer.OfferId)
      history.push(
        ROUTE_PATHS.UPLOAD,
        {
          claimCode,
          offer: offer.OfferId,
          iSubmitID,
          phone: offer.PhoneNumber,
          errorMessage: "",
          isSubmitting: false,
          address1: offer.Address1,
          address2: offer.Address2,
          city: offer.City,
          postalCode: offer.PostalCode
        })
    }
  }

  return (
    <tr className={rowClassName}>
      <td className="expirationDateCol">{getDateAsString(offer.ExpirationDate)}</td>
      <td className="offerNameCol">{offer.OfferDescription}</td>
      <td>{offer.isRejected?"Denied":(offer.HasSubmitted?"Claimed":"Available")}</td>      
      {
      offer.isRejected?
      <td><div className={linkClassName} onClick={() => ReSubmit(offer.SubmitId, offer.ClaimCode)}>{language.offers.RESUBMIT}</div></td>
      :
      <td><div className={linkClassName} onClick={() => onOfferClick(offer.PhoneNumber, offer.ClaimCode, offer.OfferId, offer.SubmitId)}>{linkText}</div></td>      
      }
    </tr>
  )
})

OffersRowComponent.propTypes = {
  rowClassName: PropTypes.string,
  linkClassName: PropTypes.string,
  offer: PropTypes.object,
  onOfferClick: PropTypes.func.isRequired,
  linkText: PropTypes.string,
  history: PropTypes.object
}
