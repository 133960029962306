import React, {useContext, useState} from "react"

import {LoadingComponent} from "../../components"

import {AppContext, LanguageContext} from "../context"

export const MetaCardInformationForm = () => {
  const {state} = useContext(AppContext)
  const {language} = useContext(LanguageContext)
  const [isLoading, setIsLoading] = useState(true)
  const iSubmission = state.iSubmission
  const cardInformation = state.metaVirtualCard.info

  const iframeLoaded = () => {
    if (isLoading) {
      setIsLoading(false)
    }
  }

  return (
    <form noValidate>
      <div className="d-flex flex-column justify-content-center">
        <div className="mb-3" style={{
          background: 'lightgrey',
          padding: '10px',
          fontWeight: '700',
          paddingLeft: '5%',
          textTransform: 'uppercase'
        }}>
          {language.virtualCard.CURRENT_BALANCE}: ${cardInformation.TotalAmount}
        </div>
        <div className="container container-padded container-fluid">
          <div className="row ml-0 mr-0 pb-5">
            <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12 text-center">
              {isLoading &&
                <LoadingComponent className="mb-3" isChatSpinner={false} />}
              <div className="myIframe">
                <iframe title="Virtual Card" style={{minHeight: "420px", minWidth: "311px"}} onLoad={() => { iframeLoaded() }} scrolling="no" src={cardInformation.CardImageUrl} allowFullScreen frameBorder="0" id="metacardimage" />
              </div>
            </div>
            <div className="col-md-6 offset-md-3 col-sm-12 col-xs-12 text-center no-pad-xs">
              <p className="ac-details">{language.virtualCard.DETAILS}:</p>
              <div className="container">
                <div className="row">
                  <div className="col">
                    {language.virtualCard.ADDRESS}
                  </div>
                  <div className="col">
                    {iSubmission.address1}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {language.virtualCard.CITY_STATE_ZIP}
                  </div>
                  <div className="col">
                    {iSubmission.city}, {iSubmission.state} {iSubmission.postalCode}
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {language.virtualCard.PHONE}
                  </div>
                  <div className="col">
                    {iSubmission.phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
